<template>
  <div class="mx-6 px-16">
    <v-row>
      <v-col
        cols="12"
        class="text-center py-0"
      >
        <h2 class="menu-header white--text">
          Análises de Produtor
        </h2>

        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-card
      dark
      color="transparent diff-list"
      style="margin-top:20px"
    >
      <v-card-title>
        <v-row>
          <v-col
            md="3"
            class="pt-0"
          >
            <v-date-range-picker
              v-model="filters.dateRange"
              dark
              @change="loadReport"
            />
          </v-col>

          <v-col
            md="3"
            class="pt-0"
          >
            <routes-autocomplete-filter
              v-model="filters.routes"
              label="Rota"
              dark
              multiple
              :return-object="false"
              @change="loadReport"
            />
          </v-col>

          <v-col
            md="3"
            class="pt-0"
          >
            <person-autocomplete-filter
              v-model="filters.producers"
              label="Produtor"
              type="PRODUCER"
              multiple
              :return-object="false"
              @change="loadReport"
            />
          </v-col>

          <v-col
            md="3"
            class="pt-0"
          >
            <v-select
              v-model="filters.types"
              :items="[
                'INCONFORMIDADE',
                'DESCARTE',
                'ANALISE',
              ]"
              clearable
              filled
              hide-details
              prepend-inner-icon="fact_check"
              label="Tipo"
              multiple
              small-chips
            />
          </v-col>
        </v-row>
      </v-card-title>

      <data-table
        ref="report"
        item-key="id_descarga_analise"
        name="Análises de Produtor"
        dark
        :loading="loading"
        :headers="headers"
        :items="filteredItems"
        show-custom-group
      >
        <template #[`item.tipo`]="{ value }">
          <v-chip
            small
            :color="getTypeColor(value)"
          >
            {{ value }}
          </v-chip>
        </template>

        <template
          v-for="(param, idx) of params"
          #[`item.analises.${param.campo}`]="{ value }"
        >
          <span
            :key="idx"
            :class="{
              'red lighten-1 px-1 font-weight-bold' : value.inconforme
            }"
          >
            {{ value.resultado }}
          </span>
        </template>

        <template #[`footer.prepend`]>
          <div class="text-caption text-left ml-4">
            Total de
            <v-chip
              small
              class="mx-2 mb-0"
            >
              {{ items.length }}
            </v-chip>
            registros
          </div>
        </template>
      </data-table>
    </v-card>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <v-overlay v-model="loading">
      Carregando ...
    </v-overlay>
  </div>
</template>

<script>
import moment from "moment-timezone";
import _ from "lodash";

import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";

export default {
  components: {
    VDateRangePicker,
    RoutesAutocompleteFilter,
    PersonAutocompleteFilter,
  },

  data() {
    return {
      loading: false,

      filters: {
        dateRange: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
        types: [],
        routes: [],
        producers: [],
        search: '',
      },

      items: [],

      params: [],
    };
  },

  computed: {

    headers() {
      const headers = [
        { text: 'Codigo', value: 'cod_itinerario', width: 150, align: 'center' },
        { text: 'Data/Hora', value: 'data_hora_descarga', width: 150, formatter: value => this.formatDate(value, 'DD/MM/YYYY HH:mm'), align: 'center' },
        { text: 'Rota', value: 'nome_rota', width: 150, align: 'center' },
        { text: 'Tipo', value: 'tipo', width: 120, formatter: value => this.truncate(value, 11), align: 'center' },
        { text: 'Codigo laticinio', value: 'codigo_laticinio', width: 80, align: 'center' },
        { text: 'Nome', value: 'nome_produtor', width: 200, align: 'center' },
        { text: 'Coleta (L)', value: 'quantidade_coleta', width: 80, align: 'center', formatter: value => this.formatInt(value) },
        { text: 'Inconf. (L)', value: 'volume_liquido', width: 80, align: 'center', formatter: value => this.formatInt(value) },
        { text: 'Tanque', value: 'tanque', width: 80, align: 'center' },
      ];

      headers.push(...this.params.map(param => {
        let text = param.descricao;

        if (param.padrao) {
          text += ` (${param.padrao})`;
        }

        return { text, value: `analises.${param.campo}`, formatter: value => value.resultado };
      }))

      return headers;
    },

    filteredItems() {
      if (_.isEmpty(this.filters.types) && _.isEmpty(this.filters.search)) {
        return this.items;
      }

      const search = this.filters.search.toUpperCase().trim();

      return this.items.filter(item => {
        const hasType = _.isEmpty(this.filters.types) || this.filters.types.includes(item.tipo);
        const hasSearch = !search || JSON.stringify(Object.values(item)).toUpperCase().includes(search);

        return hasType && hasSearch;
      });
    },

  },

  created() {
    this.loadAnalysisParams();
  },

  mounted() {
    this.loadReport();
  },

  methods: {
    async loadAnalysisParams() {
      try {
        const { data } = await this.$axios.get(`/descargaColeta/parametrosAnalises`);

        this.params = data;
      } catch (error) {
        console.warn(error);
      }
    },

    async loadReport() {
      try {
        this.loading = true;

        const [startDate, endDate] = this.filters.dateRange;

        const { data } = await this.$axios.get(`/descargaColeta/listaAnalisesProdutor`, { params: {
          data_inicio: startDate || moment().format('YYYY-MM-DD'),
          data_fim: endDate || moment().format('YYYY-MM-DD'),
          rotas: this.filters.routes,
          produtores: this.filters.producers,
        } } );

        this.items = data;
      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    exportExcel() {
      const [startDate, endDate] = this.filters.dateRange;
      const filename = `Análises produtor - ${moment(startDate || moment()).format('DD.MM')} - ${moment(endDate || moment()).format('DD.MM')}`;

      this.$refs.report.exportExcel(null, filename);
    },

    print() {
      const [startDate, endDate] = this.filters.dateRange;
      const title = `Análises de produtor - ${moment(startDate || moment()).format('DD.MM')} - ${moment(endDate || moment()).format('DD.MM')}`;
      this.$refs.report.print(null, title, true);
    },

    getTypeColor(value) {
      if (value === 'DESCARTE')
        return 'red darken-4';

      if (value === 'INCONFORMIDADE')
        return 'yellow darken-4';

      return 'green';
    },

    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value),
    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
    formatInt: (value) => !value ? '' : parseInt(value),
    truncate: (value, length) => _.truncate(value, { length }),
  },

}
</script>
