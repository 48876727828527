<template>
  <div style="min-width: 150px;">
    <v-progress-linear
      v-if="loading"
      color="white"
      indeterminate
      rounded
      height="6"
      style="margin-top: 15px; margin-bottom: 15px;"
    />
    <div v-else>
      <v-badge
        v-if="isWeightMeasurementVisible"
        :value="hasWeightMeasurement"
        color="green darken-1"
        icon="verified"
        overlap
        dark
        offset-x="11"
        offset-y="11"
        class="mr-3"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              dark
              icon
              depressed
              :x-large="$vuetify.breakpoint.width < 1000"
              v-on="on"
              @click.stop="showWeightMeasurement"
            >
              <v-icon>
                home_work
              </v-icon>
            </v-btn>
          </template>

          Portaria
        </v-tooltip>
      </v-badge>

      <v-badge
        v-if="isLoadTanksVisible"
        :value="hasLoadCompleted"
        color="green darken-1"
        icon="verified"
        overlap
        dark
        offset-x="11"
        offset-y="11"
        class="mr-3"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              dark
              icon
              depressed
              :x-large="$vuetify.breakpoint.width < 1000"
              :disabled="hasLoadCompleted"
              v-on="on"
              @click.stop="showTankLoad"
            >
              <v-icon>
                system_update_alt
              </v-icon>
            </v-btn>
          </template>

          Plataforma
        </v-tooltip>
      </v-badge>

      <v-badge
        v-if="isTanksAnalysisVisible"
        :value="hasTanksAnalysisCompleted || hasTanksError"
        :color="tanksAnalysisStatusBadge.color"
        :icon="tanksAnalysisStatusBadge.icon"
        overlap
        dark
        offset-x="11"
        offset-y="11"
        class="mr-3"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              dark
              icon
              depressed
              :x-large="$vuetify.breakpoint.width < 1000"
              :disabled="!isTanksAnalysisEnabled"
              v-on="on"
              @click.stop="showTankAnalysis"
            >
              <v-icon>
                biotech
              </v-icon>
            </v-btn>
          </template>

          Análise de Tanque
        </v-tooltip>
      </v-badge>

      <v-badge
        v-if="isProducersAnalysisVisible"
        :value="hasProducersAnalysisCompleted"
        :color="producersAnalysisStatusBadge.color"
        :icon="producersAnalysisStatusBadge.icon"
        overlap
        dark
        offset-x="11"
        offset-y="11"
        class="mr-3"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              dark
              icon
              depressed
              :x-large="$vuetify.breakpoint.width < 1000"
              :disabled="!isProducersAnalysisEnabled"
              v-on="on"
              @click.stop="showProducerAnalysis"
            >
              <v-icon>
                person_search
              </v-icon>
            </v-btn>
          </template>

          Análise dos Produtores
        </v-tooltip>
      </v-badge>

      <v-badge
        v-if="isUnloadVisible"
        :value="hasUnloadCompleted"
        color="green darken-1"
        icon="verified"
        overlap
        dark
        offset-x="11"
        offset-y="11"
        class="mr-3"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              dark
              icon
              depressed
              :x-large="$vuetify.breakpoint.width < 1000"
              :disabled="!hasTanksToUnload"
              v-on="on"
              @click.stop="showTankUnload"
            >
              <v-icon>
                mediation
              </v-icon>
            </v-btn>
          </template>

          Plataforma
        </v-tooltip>
      </v-badge>

      <v-menu
        v-if="hasChecklistsAccess"
        bottom
        left
        :min-width="200"
        @input="showChecklists"
      >
        <template #activator="{ on: dialog }">
          <v-tooltip bottom>
            <template #activator="{ on: tooltip }">
              <v-btn
                dark
                icon
                depressed
                :x-large="$vuetify.breakpoint.width < 1000"
                v-on="{ ...dialog, ...tooltip }"
              >
                <v-icon>
                  receipt_long
                </v-icon>
              </v-btn>
            </template>

            Checklists
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item
            v-for="(checklist, index) in checklists"
            :key="index"
            @click="showChecklist(checklist)"
          >
            <v-list-item-icon>
              <v-badge
                :value="!!checklist.answerId"
                color="green darken-1"
                icon="verified"
                overlap
                dark
                offset-x="11"
                offset-y="11"
                class="mr-3"
              >
                <v-icon color="secondary">
                  receipt_long
                </v-icon>
              </v-badge>
            </v-list-item-icon>
            <v-list-item-title>
              {{ checklist.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-tooltip
        v-if="isConcludeVisible"
        bottom
      >
        <template v-slot:activator="{ on }">
          <v-btn
            dark
            icon
            :x-large="$vuetify.breakpoint.width < 1000"
            :disabled="!isConcludeEnabled"
            v-on="on"
            @click.stop="showConclusionDialog"
          >
            <v-icon>
              done_all
            </v-icon>
          </v-btn>
        </template>

        Concluir
      </v-tooltip>
    </div>

    <div>
      <weight-measurement-modal
        ref="weightMeasurementModal"
        :has-all-processes-completed="hasAllProcessesCompleted"
        @onWeightChange="onWeightChange"
        @onWeightMeasurementSave="onWeightMeasurementSave"
      />

      <tanks-analysis-dialog
        ref="tanksAnalysisDialog"
        :editing="editing"
        :visible-analysis-params="visibleAnalysisParams"
        :ignore-errors="ignoreAnalysisItemsErrors"
        :ignore-empty="ignoreAnalysisItemsEmpty"
        :auto-mark-out-of-range="autoMarkOutOfRange"
        :raw-products="rawProducts"
        :tanks-analysis-enable="isTanksAnalysisInternalEnabled"
        @onTanksAnalysisSave="onTanksAnalysisSave"
        @onTanksDiscarded="onTanksDiscarded"
        @reload="loadDetails"
      />

      <producer-analysis-dialog
        ref="producersAnalysisDialog"
        :visible-analysis-params="visibleAnalysisParams"
        @onTanksAnalysisSave="onTanksAnalysisSave"
        @onTanksDiscarded="onTanksDiscarded"
      />

      <tanks-unload-dialog
        ref="unloadDialog"
        :unload="unload"
        :measured="unload.measured"
        :tanks.sync="tanks"
        @onMeasuredChange="onMeasuredChange"
        @onTanksUnloaded="onTanksUnloaded"
      />

      <unload-conclusion-dialog
        ref="unloadConclusionDialog"
        :unload="unload"
        @onUnloadConclude="onUnloadConclude"
      />

      <itinerary-transfer-dialog
        v-model="loadDialog.show"
        :itinerary-id="loadDialog.itineraryId"
        :type="loadDialog.type"
        :vol="loadDialog.vol"
        @onSiloTransferred="onTanksLoaded"
      />

      <questionnaire-form-dialog
        ref="questionnaireFormDialog"
        object="DESCARGA"
        :unload="unload"
      />
    </div>
  </div>
</template>

<script>
import * as _ from "lodash";
import moment from "moment";
import { round } from "@/Support/Resources/utils.js";

import WeightMeasurementModal from "@/Domains/Platform/Unload/Components/WeightMeasurementModal.vue";
import TanksAnalysisDialog from "@/Domains/Platform/Unload/Components/TanksAnalysisDialog.vue";
import TanksUnloadDialog from "@/Domains/Platform/Unload/Components/TanksUnloadDialog.vue";
import UnloadConclusionDialog from "@/Domains/Platform/Unload/Components/UnloadConclusionDialog.vue";
import ItineraryTransferDialog from "@/Domains/Platform/Silos/Components/ItineraryTransferDialog.vue";
import ProducerAnalysisDialog from "@/Domains/Platform/Unload/Components/ProducerAnalysisDialog.vue";
import QuestionnaireFormDialog from '@/Domains/Questionnaires/Components/QuestionnaireFormDialog.vue';

import AnalysisPatternValidator from "@/Domains/Platform/Unload/Services/AnalysisPatternValidator.js";
import AnalysisDictionary from "@/Domains/Platform/Unload/Services/AnalysisDictionary.js";

const dependents = [
  'betaLactams', 'tetracycline', 'sulphonamide', 'quinolones', 'aminoglykosides', 'macrolides', 'anfenicois',
  'sodiumHydroxide', 'bicarbonateOfSoda', 'sucrose', 'starch', 'chlorides', 'chlorine', 'peroxide', 'formol', 'neomicina'
];

export default {

  components: {
    ProducerAnalysisDialog,
    WeightMeasurementModal,
    TanksAnalysisDialog,
    TanksUnloadDialog,
    UnloadConclusionDialog,
    ItineraryTransferDialog,
    QuestionnaireFormDialog,
  },

  props: {
    editing: {
      type: Boolean,
    },

    row: {
      type: Object,
      default: () => ({}),
    },

    rawProducts: {
      type: Array,
      default: () => ([]),
    },

    prefetch: {
      type: Boolean,
    }

  },

  data() {
    return {
      loading: false,

      // Objeto com os dados da descarga
      unload: {},

      settings: {},

      loadDialog: {
        show: false,
        type: null,
        itineraryId: null,
        vol: 0,
      },

      ignoreAnalysisItemsErrors: ['temp', 'acidity'],

      ignoreAnalysisItemsEmpty: ['waterPercentage', 'waterLiters'],

      checklists: [],

      tanksAnalysisEnable: false,
    }
  },

  computed: {

    isFlowMeter() {
      if (['vazao', 'medidor'].includes(this.settings.measurementParam)) {
        return true;
      }
      return false;
    },

    /**
     * Recupera o role do usuário
     * @returns {String}
     */

    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },

    //permissão portaria
    hasConciergeAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "platform-unload-concierge" && o.tipo === "COMPONENTE");
    },
    //permissao analise tank
    hasAnalysisTankAccess () {
      return this.isAdmin || this.userResources.some(o => o.recurso === "platform-unload-analysis-tank" && o.tipo === "COMPONENTE");
    },
    //permissao analise produtor
    hasAnalysisProducerAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "platform-unload-analysis-producer" && o.tipo === "COMPONENTE");
    },
    //permissao plataforma
    hasPlatformAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "platform-unload-platform" && o.tipo === "COMPONENTE");
    },
    //permissao concluir
    hasFinishAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "platform-unload-finish" && o.tipo === "COMPONENTE");
    },

    hasChecklistsAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "platform-checklist" && o.tipo === "COMPONENTE");
    },

    isWeightMeasurementVisible() {
      return this.hasConciergeAccess;
    },

    isLoadTanksVisible() {

      if (this.unload.operation !== 'CARGA') {
        return false;
      }

      if (this.editing) {
        return false;
      }

      return this.hasPlatformAccess;
    },

    isTanksAnalysisVisible() {
      return this.hasAnalysisTankAccess;
    },

    isProducersAnalysisVisible() {
      if (this.unload.operation === 'CARGA') {
        return false;
      }

      if (this.unload.itinerary.type !== 'coleta') {
        return false;
      }

      return this.hasAnalysisProducerAccess;
    },

    /**
     * CARGA:
     *    Habilita o botão de análises de tanques apenas após a carga efetuada
     * DESCARGA:
     *    Habilita o botão de analises de tanques apenas se não houver descarga concluída,
     *    caso contrário, o botão de análises de tanques será habilitado apenas para administradores
     */
    isTanksAnalysisEnabled() {
      if (!this.unload.id) {
        return false
      }

      if (this.unload.operation === 'CARGA') {
        return this.hasLoadCompleted
      }

      return true;
    },

    /**
     * Permição interna no modal
     */
    isTanksAnalysisInternalEnabled() {
      if (!this.hasUnloadCompleted) {
        return true;
      }

      return this.canEditTankAnalysis;
    },

    /**
     * Se já houver descarga concluída, o botão de análises de produtores será habilitado apenas para administradores
     */
    isProducersAnalysisEnabled() {
      if (!this.unload.id) {
        return false
      }

      if (!this.hasUnloadCompleted) {
        return true;
      }

      return this.canViewConcludedProducerAnalysis;
    },

    isUnloadVisible() {
      if (this.unload.operation !== 'DESCARGA') {
        return false;
      }

      return this.hasPlatformAccess
    },

    isConcludeVisible() {
      if (this.editing) {
        return false;
      }

      if (!this.hasAllTanksProcessed) {
        return false;
      }

      if (this.settings.measurementParam === 'balanca' && !(this.unload.measured > 0)) {
        return false;
      }

      return this.hasFinishAccess;
    },

    isConcludeEnabled() {
      if (!this.settings.allAnalysisItemsRequired) {
        return true;
      }

      return !this.hasTanksWithEmptyAnalysis;
    },

    generalSettings() {
      return this.$store.state.settings.plataforma || {};
    },

    hasWeightMeasurement() {
      const inletWeight = parseInt(this.unload.inletWeight) || 0;
      const outletWeight = parseInt(this.unload.outletWeight) || 0;

      if (this.settings.measurementParam === 'balanca') {

        if (_.isEmpty(this.unload.entrancedAt)) {
          return false;
        }

        return !(inletWeight === 0 || outletWeight === 0);
      }

      return !_.isEmpty(this.unload.entrancedAt) && !_.isEmpty(this.unload.departureAt)
    },

    hasTanksAnalysisCompleted() {
      if (this.unload.hasTanksError) {
        return true;
      }

      if (_.isEmpty(this.unload.tanks)) {
        return false;
      }

      return this.unload.tanks.every(({ status }) => status !== 'PENDING');
    },

    hasProducersAnalysisCompleted() {
      if (this.unload.hasProducersError) {
        return true;
      }

      if (_.isEmpty(this.unload.tanks)) {
        return false;
      }

      return this.unload.tanks.every(({ status }) => status !== 'PENDING');
    },

    hasTanksWithEmptyAnalysis() {
      return this.unload.tanks.some(tank => !_.isEmpty(tank.emptyFields));
    },

    hasTanksWithProblems() {
      return this.unload.tanks.some(tank => !_.isEmpty(tank.problems));
    },

    hasAllProcessesCompleted() {
      if (!this.settings.allAnalysisItemsRequired) {
        return true
      }

      // CARGA - SPOT, TRANSFERENCIAS
      if (this.unload.operation === 'CARGA') {
        return this.hasTanksAnalysisCompleted && this.hasLoadCompleted;
      }

      // DESCARGA - SPOT, TRANSFERENCIAS
      if (this.unload.itinerary.type !== 'coleta') {
        return this.hasTanksAnalysisCompleted && this.hasUnloadCompleted;
      }

      // DESCARGA - COLETA
      return this.hasTanksAnalysisCompleted && this.hasProducersAnalysisCompleted && this.hasUnloadCompleted;
    },

    hasTanksError() {

      if (this.unload.hasTanksError) {
        return true;
      }

      if (_.isEmpty(this.unload.tanks)) {
        return false;
      }

      return this.hasTanksWithEmptyAnalysis || this.hasTanksWithProblems;
    },

    tanksAnalysisStatusBadge() {
      if (this.hasTanksError) {
        return {
          icon: 'warning',
          color: 'yellow darken-1'
        }
      }

      return {
        icon: 'verified',
        color: 'green darken-1'
      }
    },

    producersAnalysisStatusBadge() {

      if (this.hasTanksError || this.unload.hasProducersError) {
        return {
          icon: 'warning',
          color: 'yellow darken-1'
        }
      }

      return {
        icon: 'verified',
        color: 'green darken-1'
      }
    },

    hasTanksToUnload() {
      if (['UNLOADED', 'LOADED'].includes(this.unload.status)) {
        return true;
      }

      if (_.isEmpty(this.unload.tanks)) {
        return false;
      }

      const hasTanksToUnloadByStatus = this.unload.tanks.some(tank => {
        return ['TO_UNLOAD', 'UNLOADED', 'TO_UNLOAD_RESTRICT', 'UNLOADED_RESTRICT'].includes(tank.status);
      });

      // Caso não tenha tanque para descargar pelos status, não prosseguimos com as validações
      if (!hasTanksToUnloadByStatus) {
        return false;
      }

      // Neste ponto, existem tanques para descarregar, caso o parâmetro não esteja definido para bloquear, deixar prosseguir
      if (!this.settings.allAnalysisItemsRequired) {
        return true;
      }

      const hasTankWithEmptyItems = this.unload.tanks.some(tank => !_.isEmpty(tank.emptyFields));

      return !hasTankWithEmptyItems;
    },

    hasLoadCompleted() {
      return !_.isEmpty(this.unload.tanks);
    },

    hasUnloadCompleted() {
      // Caso o estado da descarga seja de carregado ou de descarregado, considerar ela completa
      if (['LOADED', 'UNLOADED'].includes(this.unload.status)) {
        return true;
      }

      if (_.isEmpty(this.unload.tanks)) {
        return false;
      }

      return this.unload.tanks.every(({ status }) => ['UNLOADED', 'TO_DISCARD', 'DISCARDED', 'UNLOADED_RESTRICT', 'TO_RETURN', 'RETURNED'].includes(status));
    },

    hasAllTanksProcessed() {

      if (_.isEmpty(this.unload)) {
        return false;
      }

      if (this.unload.status === 'WAITING') {
        return false;
      }

      if (_.isEmpty(this.unload.tanks)) {
        return false;
      }

      return this.unload.tanks.every(tank => ['UNLOADED', 'DISCARDED', 'LOADED', 'UNLOADED_RESTRICT', 'RETURNED'].includes(tank.status));
    },

    netWeight() {

      if (_.isEmpty(this.unload.id)) {
        return '';
      }

      return (this.unload.inletWeight - this.unload.outletWeight) || '';
    },

    hasDensityParam() {
      return this.visibleAnalysisParams.includes('density')
    },

    densityAvg() {

      if (!this.hasDensityParam) {
        return 1.0;
      }

      if (_.isEmpty(this.unload.tanks)) {
        return 0.0;
      }

      const densityArray = this.unload.tanks.map(tank => {
        const density = _.get(tank, 'analysis.density.value', null);

        return parseFloat(density) || 0.0;
      }).filter(n => n);

      if (_.isEmpty(densityArray)) {
        return 0.0;
      }

      return (_.mean(densityArray) / 1000) || 0.0;
    },

    difference() {

      const measured = parseInt(this.unload.measured) || 0;

      if (measured === 0) {
        return 0;
      }

      const totalVol = _.sumBy(this.unload.tanks, 'vol');

      if (totalVol === measured) {
        return;
      }

      return measured - totalVol;
    },

    tanks: {
      get() {
        if (_.isEmpty(this.unload.tanks)) {
          return [];
        }

        let vale = parseInt(this.unload.vale) || 0;
        let measured = parseInt(this.unload.measured) || 0;

        let tanksWithMeasured = this.unload.tanks.filter(tank => tank.measured);

        const measuredTanksVale = _.sumBy(tanksWithMeasured, 'vol');

        if (measuredTanksVale > 0) {
          vale -= measuredTanksVale;
          measured -= _.sumBy(tanksWithMeasured, 'measured');
        }

        let tanks = this.unload.tanks.map(tank => {

          const rateOfTotal = tank.vol / vale;
          let difference = (this.difference * rateOfTotal).toFixed(0);
          difference = parseInt(difference) || 0;

          if (measured === 0) {
            difference = 0;
          }

          if (measured === vale) {
            difference = 0;
          }

          if (tank.measured) {
            difference = tank.measured - tank.vol;
          }

          return {
            ...tank,
            rateOfTotal,
            difference,
            availableVol: tank.vol + difference,
            availableVolToTransfer: 0,
            transferring: 0,
          }
        });

        if (measured === 0) {
          return tanks;
        }

        const difference = this.difference || 0;
        const totalDifference = _.sumBy(tanks, 'difference');
        const differenceRest = difference - totalDifference;

        if (totalDifference === 0) {
          return tanks;
        }

        const [ tank, ...rest ] = tanks;

        return [
          {
            ...tank,
            difference: tank.difference + differenceRest,
            availableVol: tank.vol + (tank.difference + differenceRest),
          },
          ...rest,
        ];
      },
      set(newValue) {
        this.unload.tanks = newValue;
      }
    },

    autoMarkOutOfRange() {
      if (this.unload.operation === 'DESCARGA' && this.hasUnloadCompleted) {
        return false;
      }

      const rawProductId = this.unload.rawProduct.id;
      const settings = this.$store.state.settings;
      const config = _.get(settings, `plataforma.parametros_analise.configuracao.${rawProductId}`, {});

      return config.possui_config_restricao && config.marcar_automaticamente;
    },

    analysisParams() {
      if (_.isEmpty(this.unload.rawProduct)) {
        return [];
      }

      const rawProductId = this.unload.rawProduct.id;
      const settings = this.$store.state.settings;
      const analysisParams = _.get(settings, `plataforma.parametros_analise.materia_prima.${rawProductId}`);

      if (_.isEmpty(analysisParams)) {
        return [];
      }

      return analysisParams.reduce((acc, param) => {
        const name = AnalysisDictionary[param.parametro];
        acc[name] = {
          unit: param.unidade_medida?.unidade,
          rule: param.padrao,
          restrictionRule: param.restricao,
        };

        return acc;
      }, {});
    },

    visibleAnalysisParams() {
      return Object.keys(this.analysisParams);
    },

    tolerance() {
      let tolerance = parseInt(this.generalSettings.tolerancia_medidos_plataforma) || 0;

      if (this.generalSettings.tipo_tolerancia_medidos_plataforma === "PRODUTOR") {
        const producers = this.tanks
          .flatMap(({ producers }) => producers.map(({ id }) => id)) // Get producer ID from each tank
          .filter((id, pos, self) => self.indexOf(id) == pos); // remove producer ID duplicates from array
        tolerance = producers.length * parseInt(this.generalSettings.tolerancia_medidos_produtor_plataforma) || 0;
      }
      else if (this.generalSettings.tipo_tolerancia_medidos_plataforma === "PERCENTUAL") {
        const percentual = parseFloat(this.generalSettings.tolerancia_medidos_percentual_plataforma);

        let vale = this.unload.vale;
        if (_.isObject(vale)) {
          vale = vale.current;
        }

        tolerance = percentual ? ((vale * percentual) / 100) : 0;
      }

      return tolerance;
    },

    canEditTankAnalysis() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'edit-tank-analysis' && o.tipo === 'COMPONENTE');
    },

    canViewConcludedProducerAnalysis() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'view-concluded-producer-analysis' && o.tipo === 'COMPONENTE');
    },

  },

  created() {
    this.unload = this.row;
  },

  mounted() {
    this.loading = true;
    // Separa os contextos para não ter que atualizar a tabela da view de lista
    this.unload = this.row;

    this.settings = {
      measurementParam: this.generalSettings.medicao_descarga_plataforma || 'balanca',
      allAnalysisItemsRequired: this.generalSettings.obriga_lancamento_todas_analises_descarga || false,
    };

    this.loading = false;

    if (this.prefetch) {
      return this.loadDetails();
    }
  },

  methods: {

    async showWeightMeasurement() {
      await this.loadDetails();

      this.$refs.weightMeasurementModal.show(this.unload);
    },

    async showTankAnalysis() {
      await this.loadDetails();

      return this.$refs.tanksAnalysisDialog.show(this.unload);
    },

    async showProducerAnalysis() {
      await this.loadDetails();

      return this.$refs.producersAnalysisDialog.show(this.unload);
    },

    showTankLoad() {
      this.loadDialog = {
        show: true,
        type: this.unload.itinerary.type === 'transferencia' ? 'unidade' : 'spot',
        itineraryId: this.unload.itinerary.id,
        vol: this.unload.vale || 0,
      }
    },

    async showTankUnload() {
      await this.loadDetails();

      if (this.settings.measurementParam === 'vale' && this.unload.measured === 0 && this.unload.vale > 0) {
        this.unload.measured = this.unload.vale;
      }

      return this.$refs.unloadDialog.show();
    },

    showConclusionDialog() {
      return this.$refs.unloadConclusionDialog.show();
    },

    async onTanksAnalysisSave() {
      const rawProductId = this.unload.rawProduct.rawId || this.unload.rawProduct.id;
      if (rawProductId !== this.unload.rawProductInput.id) {
        this.unload.rawProduct = { ...this.unload.rawProductInput };
        this.$emit('onRawProductUpdated', this.unload.id, this.unload.rawProduct);
      }

      /**
       * Ao salvar as análises deve ser recarregado os dados da descarga,
       * pois a plataforma ou outro usuário pode ter feito alguma edição neste meio tempo
       */
      await this.loadDetails();

      this.$nextTick(() => {
        this.recalculateAllValues();

        this.saveUnload(this.editing ? 'DESCARREGADOS' : 'PROCESSANDO');
      });
    },

    onTanksDiscarded() {
      return this.loadDetails();
    },

    hasTankError(tank) {
      if (tank.vol === 0) {
        return [];
      }

      const problems = this.visibleAnalysisParams.map((field) => {
        const analysisItem = tank.analysis[field];

        if (_.isEmpty(analysisItem)) {
          return null;
        }

        const { value, rule, restrictionRule } = analysisItem;

        const ignore = this.ignoreAnalysisItemsErrors.some(errorToIgnore => errorToIgnore === field);

        if (ignore) {
          return null;
        }

        const hasError = !(new AnalysisPatternValidator().fromRule(rule).validate(value)) || (dependents.includes(field) && value === true);

        const hasRestriction = (!_.isEmpty(this.restrictionRule) && (new AnalysisPatternValidator().fromRule(restrictionRule).validate(value)));

        return {
          field,
          problem: hasError,
          restriction: hasError && hasRestriction,
        };
      }).filter((n) => n);

      return problems.filter(result => result.problem).map(result => result.field);
    },

    hasTankEmptyFields(tank) {
      if (tank.vol === 0) {
        return [];
      }

      const problems = this.visibleAnalysisParams.map((field) => {
        const analysisItem = tank.analysis[field];

        if (_.isEmpty(analysisItem)) {
          return null;
        }

        const { value, rule } = analysisItem;

        if (_.isBoolean(rule)) {
          return null;
        }

        const ignore = this.ignoreAnalysisItemsEmpty.some(emptyToIgnore => emptyToIgnore === field);

        if (ignore) {
          return null;
        }

        return {
          field,
          isEmpty: _.isNull(value) || !_.isNumber(parseFloat(value)) ,
        };
      }).filter((n) => n);

      return problems.filter(result => result.isEmpty).map(result => result.field);
    },

    async loadDetails() {
      try {
        this.loading = true;

        // Carrega os settings das análises
        const settings = this.analysisParams || {};

        const { data } = await this.$axios.post(
          `/descargaColeta/descargaDetalhe`,
          this.$qs.stringify({
            id_descarga_coletas: this.unload.id,
          }),
        );

        if (!_.isObject(data) || data.codigo === 0) {
          throw "PHP Error";
        }

        const tankStatus = {
          'PENDENTE': 'PENDING',
          'DESCARTAR': 'TO_DISCARD',
          'DESCARTADO': 'DISCARDED',
          'DESCARREGAR': 'TO_UNLOAD',
          'DESCARREGADO': 'UNLOADED',
          'CARREGADO': 'LOADED',
          'DESCARREGAR_RESTRITO': 'TO_UNLOAD_RESTRICT',
          'DESCARREGADO_RESTRITO': 'UNLOADED_RESTRICT',
          'DEVOLVER': 'TO_RETURN',
          'DEVOLVIDO': 'RETURNED',
        };

        const unloadStatus = {
          'DESCARREGANDO': 'WAITING',
          'PROCESSANDO': 'PROCESSING',
          'DESCARREGADOS': 'UNLOADED',
        }

        const detail = !_.isEmpty(data.detalhe) ? data.detalhe : [];
        const transfers = !_.isEmpty(data.transferencias) ? data.transferencias : [];
        const analysts = JSON.parse(data.descarga.analistas) || [];

        const getAnalysisRule = (param, field = 'rule') => {
          return _.get(settings, `${param}.${field}`, null);
        }

        this.unload.transfers = transfers.map(transfer => {
          return {
            siloId: transfer.id_silo,
            siloLabel: transfer.descricao,
            tank: transfer.tanque,
            tankIndex: (transfer.tanque || '').replace(/\D/g, ''),
            vol: parseInt(transfer.volume) || 0,
          };
        });

        const hasMultipleVehicles = Object.values(_.groupBy(detail, 'id_equipamento')).length > 1;

        this.unload.tanks = _.map(_.groupBy(detail, 'tanque'), (data, tank) => {

          const tankIndex = (tank || '').replace(/\D/g, '');

          const tankData = _.first(data.filter(item => !item.id_produtor)) || {};
          const producersData = data.filter(item => item.id_produtor) || [];
          let status = tankStatus[tankData.status];

          if (status !== 'UNLOADED_RESTRICT' && this.unload.transfers.find(transfer => transfer.tankIndex === tankIndex)) {
            status = 'UNLOADED';
          }

          const materia_prima = this.unload.rawProduct.name;

          const parsedTankData = {
            index: parseInt(tankIndex),
            label: hasMultipleVehicles ? `Tanque ${tankData.tanque_equipamento}` : `Tanque ${tankIndex}`,
            plate: hasMultipleVehicles ? tankData.placa : undefined,
            capacity: parseInt(tankData.volume_total) || 0,
            vol: parseInt(tankData.volume_liquido) || 0,
            transfers: this.unload.transfers.filter(transfer => transfer.tankIndex === tankIndex),
            obs: tankData.observacao,
            status,
            files: JSON.parse(tankData.anexos) || [],
            restriction: ['TO_UNLOAD_RESTRICT', 'UNLOADED_RESTRICT'].includes(status),
            discard: ['TO_DISCARD', 'DISCARDED'].includes(status),
            return: ['TO_RETURN', 'RETURNED'].includes(status),
            measureTankWeight: tankData.pesar_tanque,
            inletWeight: parseInt(tankData.peso_inicial) || 0,
            outletWeight: parseInt(tankData.peso_final) || 0,
            netWeight: parseInt(tankData.peso_liquido) || 0,
            measured: parseInt(tankData.medidos) || 0,
            driverNonconformityLink: tankData.vincula_inconformidade_transportador || false,
            analysis: {
              // Campos padrão
              numero_lacre: {
                value: parseFloat(tankData.numero_lacre) || null,
                rule: getAnalysisRule('numero_lacre'),
                decimals: 0,
              },
              temp: {
                value: parseFloat(tankData.temperatura) || null,
                rule: getAnalysisRule('temp'),
                restrictionRule: getAnalysisRule('temp', 'restrictionRule'),
                unit: `ºC`,
                decimals: 1,
              },
              acidity: {
                value: parseFloat(tankData.acidez) || null,
                rule: getAnalysisRule('acidity'),
                restrictionRule: getAnalysisRule('acidity', 'restrictionRule'),
                unit: getAnalysisRule('acidity', 'unit') || `ºD`,
                decimals: 3,
              },
              cryoscopy: {
                value: tankData.crioscopia ? parseFloat(tankData.crioscopia).toFixed(3) : '-0.5',
                rule: getAnalysisRule('cryoscopy'),
                restrictionRule: getAnalysisRule('cryoscopy', 'restrictionRule'),
                default: -0.5,
                unit: getAnalysisRule('cryoscopy', 'unit') || `ºC`,
                decimals: 3,
              },
              waterPercentage: {
                value: parseFloat(tankData.porcentagem_agua) || null,
                rule: getAnalysisRule('waterPercentage'),
                restrictionRule: getAnalysisRule('waterPercentage', 'restrictionRule'),
                decimals: 2,
              },
              waterLiters: {
                value: parseFloat(tankData.volume_agua) || null,
                rule: getAnalysisRule('waterLiters'),
                restrictionRule: getAnalysisRule('waterLiters', 'restrictionRule'),
                decimals: 2,
              },
              density: {
                value: parseFloat(tankData.densidade) || null,
                rule: getAnalysisRule('density'),
                restrictionRule: getAnalysisRule('density', 'restrictionRule'),
                unit: getAnalysisRule('density', 'unit') || `g/L`,
                decimals: 1,
              },
              fat: {
                value: parseFloat(tankData.gordura) || null,
                rule: getAnalysisRule('fat'),
                restrictionRule: getAnalysisRule('fat', 'restrictionRule'),
                unit: getAnalysisRule('fat', 'unit') || `%/100g`,
                decimals: 3,
              },
              esd: {
                value: parseFloat(tankData.esd) || null,
                rule: getAnalysisRule('esd'),
                restrictionRule: getAnalysisRule('esd', 'restrictionRule'),
                unit: `g/100g`,
                decimals: 3,
              },
              est: {
                value: parseFloat(tankData.est) || null,
                rule: getAnalysisRule('est'),
                restrictionRule: getAnalysisRule('est', 'restrictionRule'),
                decimals: 2,
              },
              protein: {
                value: parseFloat(tankData.proteina) || null,
                rule: getAnalysisRule('protein'),
                restrictionRule: getAnalysisRule('protein', 'restrictionRule'),
                unit: `g/100g`,
                decimals: 2,
              },
              ph: {
                value: parseFloat(tankData.ph) || null,
                rule: getAnalysisRule('ph'),
                restrictionRule: getAnalysisRule('ph', 'restrictionRule'),
                decimals: 2,
              },
              alizarol: {
                value: parseInt(tankData.alizarol) || null,
                rule: getAnalysisRule('alizarol'),
                restrictionRule: getAnalysisRule('alizarol', 'restrictionRule'),
                unit: getAnalysisRule('alizarol', 'unit') || `°GL`,
                decimals: 0,
              },
              totalSolids: {
                value: parseFloat(tankData.solidos_totais) || null,
                rule: getAnalysisRule('totalSolids'),
                restrictionRule: getAnalysisRule('totalSolids', 'restrictionRule'),
                decimals: 2,
              },
              brix: {
                value: parseFloat(tankData.brix) || null,
                rule: getAnalysisRule('brix'),
                restrictionRule: getAnalysisRule('brix', 'restrictionRule'),
                decimals: 2,
              },
              lactose: {
                value: parseFloat(tankData.lactose) || null,
                rule: getAnalysisRule('lactose'),
                restrictionRule: getAnalysisRule('lactose', 'restrictionRule'),
                decimals: 2,
              },
              reductase: {
                value: parseFloat(tankData.redutase) || null,
                rule: getAnalysisRule('reductase'),
                restrictionRule: getAnalysisRule('reductase', 'restrictionRule'),
              },

              // Inconformidades (Checkbox)
              alizarolQualit: {
                value: tankData.alizarol_qualit,
                rule: getAnalysisRule('alizarolQualit'),
              },
              mastitis: {
                value: tankData.mastite,
                rule: getAnalysisRule('mastitis'),
              },
              sensorial: {
                value: tankData.sensorial,
                rule: getAnalysisRule('sensorial'),
                hint: `Característico`,
              },
              alcohol: {
                value: tankData.alcool,
                rule: getAnalysisRule('alcohol'),
                hint: 'Negativo',
              },
              alkalinePhosphatase: {
                value: tankData.fosfatase,
                rule: getAnalysisRule('alkalinePhosphatase'),
                hint: materia_prima === 'LEITE CRU REFRIGERADO' ? 'Positivo' : 'Negativo',
              },
              clumps: {
                value: tankData.grumos,
                rule: getAnalysisRule('clumps'),
                hint: 'Ausência',
              },
              clots: {
                value: tankData.coagulos,
                rule: getAnalysisRule('clots'),
                hint: 'Ausência',
              },
              peroxidase: {
                value: tankData.peroxidase,
                rule: getAnalysisRule('peroxidase'),
                hint: materia_prima === 'LEITE CRU REFRIGERADO' ? 'Positivo' : 'Negativo',
              },
              otherSpecies: {
                value: tankData.outras_especies,
                rule: getAnalysisRule('otherSpecies'),
                hint: 'Negativo',
              },

              // Antibióticos
              antibiotic: {
                value: tankData.antibiotico,
                rule: getAnalysisRule('antibiotic'),
              },
              betaLactams: {
                value: tankData.betalactamico,
                rule: getAnalysisRule('betaLactams'),
              },
              tetracycline: {
                value: tankData.tetraciclina,
                rule: getAnalysisRule('tetracycline'),
              },
              sulphonamide: {
                value: tankData.sulfonamida,
                rule: getAnalysisRule('sulphonamide'),
              },
              quinolones: {
                value: tankData.quinolonas,
                rule: getAnalysisRule('quinolones'),
              },
              aminoglykosides: {
                value: tankData.aminoglicosideos,
                rule: getAnalysisRule('aminoglykosides'),
              },
              macrolides: {
                value: tankData.macrolideos,
                rule: getAnalysisRule('macrolides'),
              },
              anfenicois: {
                value: tankData.anfenicois,
                rule: getAnalysisRule('anfenicois'),
              },
              neomicina: {
                value: tankData.neomicina,
                rule: getAnalysisRule('neomicina'),
              },
              cefalosporina: {
                value: tankData.cefalosporina,
                rule: getAnalysisRule('cefalosporina'),
              },

              // Neutralizantes
              neutralising: {
                value: tankData.neutralizantes,
                rule: getAnalysisRule('neutralising'),
                hint: `Ausência`,
              },
              sodiumHydroxide: {
                value: tankData.soda,
                rule: getAnalysisRule('sodiumHydroxide'),
              },
              bicarbonateOfSoda: {
                value: tankData.bicarbonato,
                rule: getAnalysisRule('bicarbonateOfSoda'),
              },

              // Reconstituintes
              restoratives: {
                value: tankData.reconstituintes,
                rule: getAnalysisRule('restoratives'),
                hint: `Ausência`,
              },
              sucrose: {
                value: tankData.sacarose,
                rule: getAnalysisRule('sucrose'),
              },
              starch: {
                value: tankData.amido,
                rule: getAnalysisRule('starch'),
              },
              chlorides: {
                value: tankData.cloretos,
                rule: getAnalysisRule('chlorides'),
              },
              chlorine: {
                value: tankData.cloro,
                rule: getAnalysisRule('chlorine'),
              },
              ureia: {
                value: tankData.ureia,
                rule: getAnalysisRule('ureia'),
                hint: 'Negativo',
              },

              // Conservantes
              conservatives: {
                value: tankData.conservantes,
                rule: getAnalysisRule('conservatives'),
                hint: `Ausência`,
              },
              peroxide: {
                value: tankData.peroxido,
                rule: getAnalysisRule('peroxide'),
              },
              formol: {
                value: tankData.formol,
                rule: getAnalysisRule('formol'),
              },
              alkali: {
                value: tankData.alcalino,
                rule: getAnalysisRule('alkali'),
              },
              solubilidade: {
                value: tankData.solubilidade,
                rule: getAnalysisRule('solubilidade'),
                unit: `g/100g`,
              },
              base_seca: {
                value: tankData.base_seca,
                rule: getAnalysisRule('base_seca'),
              },

              // Outras inconformidades
              others: {
                value: tankData.outros,
                rule: getAnalysisRule('others'),
              },
            },
            producers: producersData.map(producerAnalysis => {
              return {
                id: producerAnalysis.id_produtor,
                name: producerAnalysis.nome_produtor,
                code: producerAnalysis.codigo_laticinio,
                status: tankStatus[producerAnalysis.status],
                files: JSON.parse(producerAnalysis.anexos) || [],
                unloadId: producerAnalysis.id_coleta,
                sampleNumber: producerAnalysis.numero_amostra,
                vol: parseFloat(producerAnalysis.volume_liquido),
                hasError: !!producerAnalysis.inconformidades,
                analysis: {
                  // Campos padrão
                  numero_lacre: {
                    value: producerAnalysis.numero_lacre,
                    rule: getAnalysisRule('numero_lacre'),
                    decimals: 0,
                  },
                  temp: {
                    value: parseFloat(producerAnalysis.temperatura) || null,
                    rule: getAnalysisRule('temp'),
                    unit: `ºC`,
                    decimals: 1,
                  },
                  acidity: {
                    value: producerAnalysis.acidez,
                    rule: getAnalysisRule('acidity'),
                    unit: getAnalysisRule('acidity', 'unit') || `ºD`,
                    decimals: 3,
                  },
                  cryoscopy: {
                    value: producerAnalysis.crioscopia ? parseFloat(producerAnalysis.crioscopia).toFixed(3) : '',
                    rule: getAnalysisRule('cryoscopy'),
                    unit: getAnalysisRule('cryoscopy', 'unit') || `ºC`,
                    decimals: 3,
                  },
                  waterPercentage: {
                    value: producerAnalysis.porcentagem_agua,
                    rule: getAnalysisRule('waterPercentage'),
                    decimals: 2,
                  },
                  waterLiters: {
                    value: parseFloat(producerAnalysis.volume_agua) || null,
                    rule: getAnalysisRule('waterLiters'),
                    decimals: 2,
                  },
                  density: {
                    value: producerAnalysis.densidade,
                    rule: getAnalysisRule('density'),
                    unit: getAnalysisRule('density', 'unit') || `g/ml`,
                    decimals: 1,
                  },
                  fat: {
                    value: producerAnalysis.gordura,
                    rule: getAnalysisRule('fat'),
                    unit: getAnalysisRule('fat', 'unit') || `%/100g`,
                    decimals: 3,
                  },
                  esd: {
                    value: producerAnalysis.esd,
                    rule: getAnalysisRule('esd'),
                    unit: `g/100g`,
                    decimals: 2,
                  },
                  est: {
                    value: producerAnalysis.est,
                    rule: getAnalysisRule('est'),
                    decimals: 2,
                  },
                  protein: {
                    value: producerAnalysis.proteina,
                    rule: getAnalysisRule('protein'),
                    unit: `g/100g`,
                    decimals: 2,
                  },
                  ph: {
                    value: producerAnalysis.ph,
                    rule: getAnalysisRule('ph'),
                    unit: ``,
                    decimals: 2,
                  },
                  alizarol: {
                    value: producerAnalysis.alizarol,
                    rule: getAnalysisRule('alizarol'),
                    unit: getAnalysisRule('alizarol', 'unit') || `°GL`,
                    decimals: 0,
                  },
                  totalSolids: {
                    value: producerAnalysis.solidos_totais,
                    rule: getAnalysisRule('totalSolids'),
                    decimals: 2,
                  },
                  brix: {
                    value: producerAnalysis.brix,
                    rule: getAnalysisRule('brix'),
                    decimals: 2,
                  },
                  lactose: {
                    value: producerAnalysis.lactose,
                    rule: getAnalysisRule('lactose'),
                    decimals: 2,
                  },
                  reductase: {
                    value: producerAnalysis.redutase,
                    rule: getAnalysisRule('reductase'),
                  },

                  // Inconformidades (Checkbox)
                  alizarolQualit: {
                    value: producerAnalysis.alizarol_qualit,
                    rule: getAnalysisRule('alizarolQualit'),
                  },
                  mastitis: {
                    value: producerAnalysis.mastite,
                    rule: getAnalysisRule('mastitis'),
                  },
                  sensorial: {
                    value: producerAnalysis.sensorial,
                    rule: getAnalysisRule('sensorial'),
                    hint: `Característico`,
                  },
                  alcohol: {
                    value: producerAnalysis.alcool,
                    rule: getAnalysisRule('alcohol'),
                  },
                  alkalinePhosphatase: {
                    value: producerAnalysis.fosfatase,
                    rule: getAnalysisRule('alkalinePhosphatase'),
                  },
                  clumps: {
                    value: producerAnalysis.grumos,
                    rule: getAnalysisRule('clumps'),
                  },
                  clots: {
                    value: producerAnalysis.coagulos,
                    rule: getAnalysisRule('clots'),
                  },
                  peroxidase: {
                    value: producerAnalysis.peroxidase,
                    rule: getAnalysisRule('peroxidase'),
                  },
                  otherSpecies: {
                    value: producerAnalysis.outras_especies,
                    rule: getAnalysisRule('otherSpecies'),
                  },

                  // Antibióticos
                  antibiotic: {
                    value: producerAnalysis.antibiotico,
                    rule: getAnalysisRule('antibiotic'),
                  },
                  betaLactams: {
                    value: producerAnalysis.betalactamico,
                    rule: getAnalysisRule('betaLactams'),
                  },
                  tetracycline: {
                    value: producerAnalysis.tetraciclina,
                    rule: getAnalysisRule('tetracycline'),
                  },
                  sulphonamide: {
                    value: producerAnalysis.sulfonamida,
                    rule: getAnalysisRule('sulphonamide'),
                  },
                  quinolones: {
                    value: producerAnalysis.quinolonas,
                    rule: getAnalysisRule('quinolones'),
                  },
                  aminoglykosides: {
                    value: producerAnalysis.aminoglicosideos,
                    rule: getAnalysisRule('aminoglykosides'),
                  },
                  macrolides: {
                    value: producerAnalysis.macrolideos,
                    rule: getAnalysisRule('macrolides'),
                  },
                  anfenicois: {
                    value: producerAnalysis.anfenicois,
                    rule: getAnalysisRule('anfenicois'),
                  },
                  neomicina: {
                    value: producerAnalysis.neomicina,
                    rule: getAnalysisRule('neomicina'),
                  },
                  cefalosporina: {
                    value: producerAnalysis.cefalosporina,
                    rule: getAnalysisRule('cefalosporina'),
                  },

                  // Neutralizantes
                  neutralising: {
                    value: producerAnalysis.neutralizantes,
                    rule: getAnalysisRule('neutralising'),
                    hint: `Ausência`,
                  },
                  sodiumHydroxide: {
                    value: producerAnalysis.soda,
                    rule: getAnalysisRule('sodiumHydroxide'),
                  },
                  bicarbonateOfSoda: {
                    value: producerAnalysis.bicarbonato,
                    rule: getAnalysisRule('bicarbonateOfSoda'),
                  },

                  // Reconstituintes
                  restoratives: {
                    value: producerAnalysis.reconstituintes,
                    rule: getAnalysisRule('restoratives'),
                    hint: `Ausência`,
                  },
                  sucrose: {
                    value: producerAnalysis.sacarose,
                    rule: getAnalysisRule('sucrose'),
                  },
                  starch: {
                    value: producerAnalysis.amido,
                    rule: getAnalysisRule('starch'),
                  },
                  chlorides: {
                    value: producerAnalysis.cloretos,
                    rule: getAnalysisRule('chlorides'),
                  },
                  chlorine: {
                    value: producerAnalysis.cloro,
                    rule: getAnalysisRule('chlorine'),
                  },
                  ureia: {
                    value: producerAnalysis.ureia,
                    rule: getAnalysisRule('ureia'),
                    hint: 'Negativo',
                  },

                  // Conservantes
                  conservatives: {
                    value: producerAnalysis.conservantes,
                    rule: getAnalysisRule('conservatives'),
                    hint: `Ausência`,
                  },
                  peroxide: {
                    value: producerAnalysis.peroxido,
                    rule: getAnalysisRule('peroxide'),
                  },
                  formol: {
                    value: producerAnalysis.formol,
                    rule: getAnalysisRule('formol'),
                  },
                  alkali: {
                    value: producerAnalysis.alcalino,
                    rule: getAnalysisRule('alkali'),
                  },
                  solubilidade: {
                    value: producerAnalysis.solubilidade,
                    rule: getAnalysisRule('solubilidade'),
                  },
                  base_seca: {
                    value: producerAnalysis.base_seca,
                    rule: getAnalysisRule('base_seca'),
                  },

                  // Outras inconformidades
                  others: {
                    value: producerAnalysis.outros,
                    rule: getAnalysisRule('others'),
                  },
                },
              };
            }),
          };

          return {
            ...parsedTankData,
            problems: this.hasTankError(parsedTankData),
            emptyFields: this.hasTankEmptyFields(parsedTankData),
          };
        }).sort((a, b) => a.index - b.index).filter(tank => tank.vol);

        let rawProduct = this.rawProducts.find(rawProduct => rawProduct.id === data.descarga.id_materia_prima);
        const rawProductInput = { ...rawProduct };

        // Caso seja materia prima agrupada é realizada a busca pelo id da materia prima pai
        if (rawProduct.rawMaterialGroupId) {
          rawProduct = {
            rawId: rawProduct.id,
            rawName: rawProduct.name,
            ...this.rawProducts.find(({ id }) => id === rawProduct.rawMaterialGroupId),
          }
        }

        this.unload = {
          ...this.unload,
          status: unloadStatus[data.descarga ? data.descarga.estado : 'DESCARREGANDO'],
          inletWeight: parseInt(data.descarga.peso_entrada) || null,
          outletWeight: parseInt(data.descarga.peso_saida) || null,
          netWeight: parseInt(data.descarga.peso_liquido) || 0,
          measured: parseInt(data.descarga.peso_medido) || 0,
          vale: parseInt(data.descarga.peso_vale) || 0,
          entrancedAt: data.descarga.data_hora_baixa_portaria ? moment(data.descarga.data_hora_baixa_portaria).format("DD/MM/YYYY HH:mm") : '',
          departureAt: data.descarga.data_hora_saida_portaria ? moment(data.descarga.data_hora_saida_portaria).format("DD/MM/YYYY HH:mm") : '',
          sampleTakenAt: data.descarga.hora_retirada_amostra ? moment(data.descarga.hora_retirada_amostra).format("DD/MM/YYYY HH:mm") : '',
          releasedAt: data.descarga.hora_liberacao ? moment(data.descarga.hora_liberacao).format("DD/MM/YYYY HH:mm") : '',
          invoiceNumber: data.descarga.numero_nota,
          cargaLacre: data.descarga.carga_lacre,
          cip: data.descarga.cip,
          analysts: analysts.map(analyst => {
            return {
              id: analyst.id_pessoa,
              name: analyst.nome_pessoa,
              signature: analyst.assinatura,
            };
          }),
          hasTanksError: data.descarga.inconformidades_tanque,
          hasProducersError: data.descarga.inconformidades_produtor,
          rawProduct,
          rawProductInput,
        }

        if (!this.editing && this.unload.tanks.length > 0) {
          this.$emit('onTanksUpdated', this.unload.id, this.unload.tanks);
        }
      } catch (err) {
        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar as rotas!",
          "Atenção"
        );

        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    async onTanksUnloaded(hasUnloaded) {
      this.recalculateAllValues();

      await this.saveUnload(this.editing ? 'DESCARREGADOS' : 'PROCESSANDO');

      await this.loadDetails();

      /**
       * Caso haja alteração em cargas já finalizadas é necessário chamar o método que gera a diferença para o silo
       */
      if (this.editing && hasUnloaded) {
        await this.generateTransferDifference();
      }
    },

    /**
     * Este método é necessário para agrupar todos os recalculos da tela em um único método
     */
    recalculateAllValues(measured = true) {

      if (measured) {
        this.calculateMeasured();
      }

      this.calculateDifference();
      this.calculateDiscount();
    },

    /**
     * Calcula o peso medido pela densidade x peso liquido
     */
    calculateMeasured() {

      if (_.isEmpty(this.unload.tanks)) {
        return;
      }

      this.unload.tanks = this.unload.tanks.map(tank => {

        const inletWeight = parseInt(tank.inletWeight) || 0;
        const outletWeight = parseInt(tank.outletWeight) || 0;
        const density = this.hasDensityParam ? ((parseFloat(tank.analysis.density.value) / 1000) || 0.0) : 1.0;

        // É necessário que tanto o peso de entrada quanto de saída estejam preenchidos
        if (!(inletWeight > 0) || !(outletWeight > 0) || !(density > 0.0)) {
          return tank;
        }

        const netWeight = tank.inletWeight - tank.outletWeight;
        const measured = (netWeight / density).toFixed(0);

        return {
          ...tank,
          netWeight,
          measured: parseInt(measured) || 0,
        }
      });

      if (this.densityAvg === 0.0) {
        return;
      }

      // É necessário que tanto o peso de entrada quanto de saída estejam preenchidos
      if (!(this.unload.inletWeight > 0) || !(this.unload.outletWeight > 0)) {
        return;
      }

      const measured = (this.netWeight / this.densityAvg).toFixed(0);

      this.unload.measured = parseInt(measured) || 0;
    },

    /**
     * Calcula a diferença de volume entre o Vale (Quantidade informada pelo motorista) e os Medidos (Peso real retirado do veículo)
     */
    calculateDifference() {
      if (_.isEmpty(this.unload.id) || this.unload.measured === 0) {
        this.unload.difference = 0;

        return;
      }

      this.unload.difference = (this.unload.measured - this.unload.vale) || 0;
    },

    /**
     * Calcula o Desconto, que é o volume em litros que será descontado do motorista caso o volume informado por ele, e o volume retirado do veículo não bata
     */
    calculateDiscount() {
      // Caso a diferença seja positiva, não calcula o desconto do motorista se estiver parametrizado
      if (this.unload.difference > 0 && !this.generalSettings.considerar_sobras_tolerancia) {
        this.unload.discount = 0;

        return;
      }

      // Caso não tenha diferença
      if (this.unload.difference === 0) {
        this.unload.discount = 0;

        return;
      }

      // Caso a tolerancia não estiver configurada, usa a diferença total como desconto
      if (this.tolerance === 0) {
        this.unload.discount = this.unload.difference;

        return;
      }

      // Caso a diferença esteja entre os limites da tolerancia, não desconta
      if (Math.abs(this.unload.difference) <= this.tolerance) {
        this.unload.discount = 0;

        return;
      }

      if (this.unload.difference > 0) {
        this.unload.discount = this.unload.difference - this.tolerance;

        return;
      }

      // Calculo simples do desconto efetivo do motorista
      this.unload.discount = this.unload.difference + this.tolerance;
    },

    onMeasuredChange(measured) {
      this.unload = {
        ...this.unload,
        measured,
      }

      return this.recalculateAllValues(false);
    },

    onWeightChange(unload) {
      this.unload = {
        ...this.unload,
        ...unload,
      };

      return this.recalculateAllValues();
    },

    async onWeightMeasurementSave(unload) {
      this.unload = {
        ...this.unload,
        ...unload,
      };

      this.recalculateAllValues();

      await this.saveUnload(this.editing ? 'DESCARREGADOS' : 'PROCESSANDO');

      if (this.unload.inletWeight && this.unload.outletWeight && this.unload.difference !== 0 && !_.isEmpty(this.unload.transfers)) {
        await this.generateTransferDifference();
      }

      return this.loadDetails();
    },

    async onUnloadConclude() {
      try {
        if (this.unload.operation === 'CARGA') {
          await this.saveUnload('CARREGADO');
        } else {
          await this.saveUnload('DESCARREGADOS');
        }

        // if (['vazao', 'medidor', 'vale'].includes(this.settings.measurementParam)) {
        //   return this.$emit('onUnloadConclude');
        // }

        if (this.unload.difference === 0) {
          return this.$emit('onUnloadConclude');
        }

        if (_.isEmpty(this.unload.transfers)) {
          return this.$emit('onUnloadConclude');
        }

        const hasSavedSuccessfully = await this.generateTransferDifference();

        if (hasSavedSuccessfully) {
          return this.$emit('onUnloadConclude');
        }

      } catch (err) {
        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar as rotas!",
          "Atenção"
        );

        console.log(err);
      }
    },

    async generateTransferDifference() {
      try {
        const totalTransferredVol = _.sumBy(this.unload.transfers, 'vol');

        const totalDiscardedVol = _.sumBy(this.tanks.filter(tank => ['DISCARDED', 'RETURNED'].includes(tank.status)), 'availableVol')

        let measuredWithoutDiscarded = this.unload.measured - totalDiscardedVol;

        if (this.unload.operation === 'CARGA') {
          measuredWithoutDiscarded *= -1;
        }

        const difference = measuredWithoutDiscarded - totalTransferredVol;

        if (difference === 0) {
          return true;
        }

        // Alterar quando a transferência for individual
        const [ { siloId } ] = this.unload.transfers;

        const { data } = await this.$axios.post(
          `/silo/transferenciaTanqueSilo`,
          {
            id_descarga_coletas: this.unload.id,
            peso_medido: this.unload.measured,
            id_materia_prima: this.unload.rawProduct.id,
            nome_materia_prima: this.unload.rawProduct.name,
            transferencias: [
              {
                id_descarga_coletas: this.unload.id,
                data: moment().format('YYYY-MM-DD'),
                tanque: `diferenca`,
                silos: [{
                  id_silo: siloId,
                  volume: difference,
                }],
              },
            ],
            medidor_vazao: this.isFlowMeter
          },
        );

        const { codigo } = data;

        if (!codigo) {
          throw 'Erro ao salvar';
        }

        if (data.codigo === 2) {
          this.$snotify.warning(
            data.mensagem,
            "Atenção"
          );
          return false;
        }

        return true;
      } catch (err) {
        this.$snotify.error(
          "Oops, ocorreu um erro ao gerar a diferença de transferencia!",
          "Atenção"
        );

        console.log(err);
        return false;
      }
    },

    async onTanksLoaded() {
      return this.loadDetails();
    },

    async saveUnload(status) {
      try {
        this.loading = true;

        let arrivalTime = this.unload.arrivalTime ? moment(this.unload.arrivalTime, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss') : null;

        const dataBlock = this.$store.state.settings.gerais.data_bloqueio_edicao_coleta || moment();

        if ( moment(arrivalTime) < moment(dataBlock)) {
          this.$snotify.warning(
            "Não é possivel editar/inserir coletas anteriores a " + moment(dataBlock).format("DD/MM/YY"),
            "Atenção"
          );
          return false;
        }

        // Manter esta lógica, gravar em outro campo o momento exato que a descarga é finalizada
        // if (['DESCARREGADOS', 'CARREGADO'].includes(status) && !this.editing) {
        //   arrivalTime = moment().format('YYYY-MM-DD HH:mm:ss');
        // }

        let vale = this.unload.vale;

        if (_.isObject(vale)) {
          vale = vale.current;
        }

        let descarga = {
          id_descarga_coletas: this.unload.id,
          id_itinerario: this.unload.itinerary.id,
          estado: status,
          data_hora_baixa_portaria: this.unload.entrancedAt ? moment(this.unload.entrancedAt, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss') : null,
          data_hora_saida_portaria: this.unload.departureAt ? moment(this.unload.departureAt, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss') : null,
          data_hora_entrega: arrivalTime, // data_hora_descarga
          diferenca_peso: this.unload.difference,
          volume_tolerancia: round(this.tolerance, 4),
          desconto: round(this.unload.discount, 4),
          observacao: this.unload.obs,
          peso_entrada: this.unload.inletWeight,
          peso_liquido: this.netWeight,
          peso_medido: this.unload.measured,
          peso_saida: this.unload.outletWeight,
          peso_vale: vale,
          tipo: this.unload.itinerary.type,
          veiculo: this.unload.vehicle.plate,
          motorista_id: this.unload.driver.id,
          motorista_name: this.unload.driver.name,
          tanques: this.unload.tanks.map(tank => {
            return {
              tanque: `tanque${tank.index}`,
              peso_inicial: tank.inletWeight,
              peso_final: tank.outletWeight,
              peso_liquido: tank.netWeight,
              medidos: tank.measured,
              descartado: tank.discard,
            }
          }),
        };

        // Quando for Vale, o medidos sempre será o vale!
        if (this.settings.measurementParam === 'vale') {
          descarga = {
            ...descarga,
            peso_medido: vale,
            diferenca_peso: 0,
            desconto: 0,
          }
        }

        if (['spot', 'transferencia'].includes(this.unload.itinerary.type) && ['DESCARREGADOS', 'CARREGADO'].includes(status)) {
          descarga.data_hora_descarga = this.unload.arrivalTime ? moment(this.unload.arrivalTime, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss') : moment().format('YYYY-MM-DD HH:mm:ss')
        }

        const { data } = await this.$axios.post(
          `/descargaColeta/salvaDescarga`,
          {
            descarga,
          },
        );

        if (data.codigo === 2) {
          this.$snotify.warning(
            data.mensagem,
            "Atenção"
          );
          return false;
        }

        if (data.codigo !== 1) {
          throw 'Erro ao salvar';
        }

        /**
         * Recarrega as cargas e descargas
         */
        if (!this.unload.id) {
          this.$emit('onUnloadConclude');
        }

      } catch (err) {
        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar as rotas!",
          "Atenção"
        );

        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    showChecklists: _.debounce(function (value) {
      if (!value) {
        return;
      }

      this.loadChecklists();
    }, 100),

    async loadChecklists() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get(`/questionnaires/forms`, { params: {
          objeto: 'DESCARGA',
          id_objeto: this.unload.id,
        } });

        this.checklists = data.map(item => ({
          id: item.id_formulario,
          title: item.titulo,
          answerId: item.id_formulario_customizado_cabecalho,
        }));
      } catch (error) {
        this.$snotify.error('Erro ao carregar checklists', 'Atenção');
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async showChecklist({ id, answerId }) {
      this.$refs.questionnaireFormDialog.show({
        id,
        answerId,
        objectId: this.unload.id
      });
    },

  },

}
</script>
