<template>
  <v-col
    class="pa-1"
    :md="$vuetify.breakpoint.lgAndUp?3:4"
    style="min-height: 110px !important;"
  >
    <v-card
      flat
      class="analyze-card"
      :style="{
        'border-color': hasError || hasRestrictionError ? '#F44336 !important' : '#ccc !important'
      }"
    >
      <v-card-title
        class="px-2 py-0 align-start"
        :style="(!showRestriction || typeof analysis.standard != 'object') ? 'height: 49px !important;' : undefined"
      >
        <div class="d-flex align-start full-width">
          <div>
            <slot name="prepend" />
          </div>
          <span
            class="text-subtitle-2 mt-2 text-truncate"
            :style="{
              'color': hasError || hasRestrictionError ? '#F44336 !important' : '#59595f !important'
            }"
          >
            {{ analysis.text }}
          </span>

          <div>
            <div
              v-if="units.length > 0"
              style="min-width:40px"
            >
              <v-menu
                v-if="units.length > 1"
                v-model="menu"
                offset-y
                transition="slide-y-transition"
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    color="#9E9E9E"
                    dark
                    v-bind="attrs"
                    small
                    text
                    class="pt-1"
                    v-on="on"
                  >
                    <v-tooltip
                      :disabled="!selectedUnit.description"
                      top
                    >
                      <template #activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          class="unit ml-2"
                          v-on="on"
                        >{{ selectedUnit.unit }}</span>
                      </template>
                      {{ selectedUnit.description }}
                    </v-tooltip>
                    <v-icon
                      small
                      color="black"
                    >
                      {{ menu ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="unit in units"
                    :key="unit.description"
                    @click="onSelectUnit(unit)"
                  >
                    <v-list-item-icon>
                      <v-chip
                        color="cyan darken-4"
                        outlined
                      >
                        <strong> {{ unit.unit }}</strong>
                      </v-chip>
                    </v-list-item-icon>
                    <v-list-item-content> {{ unit.description }}</v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-tooltip
                v-else
                :disabled="!selectedUnit.description"
                top
              >
                <template #activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    class="unit ml-2"
                    v-on="on"
                  >{{ selectedUnit.unit }}</span>
                </template>
                {{ selectedUnit.description }}
              </v-tooltip>
            </div>

            <div class="ml-1">
              <v-tooltip
                v-if="!!dependency"
                top
              >
                <template #activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    small
                    v-on="on"
                  >
                    info
                  </v-icon>
                </template>
                {{ dependency }}
              </v-tooltip>
            </div>
          </div>
        </div>
      </v-card-title>
      <v-card-text class="pt-2 pb-0 pl-2 pt-0">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          :disabled="disabled"
        >
          <!--Trata padrão como Object-->
          <template v-if="typeof analysis.standard == 'object'">
            <v-row justify="end">
              <v-col
                v-if="showRestriction"
                cols="12"
                class="pt-0 blue--text"
              >
                Padrão
              </v-col>
              <v-col
                v-if="inputsVisible.min"
                md="6"
                class="pt-0"
              >
                <v-text-field
                  v-model="analysis.standard.min"
                  class="mt-0 pt-0"
                  label="Minimo"
                  hide-details
                  :error="hasError"
                  type="number"
                  @change="changeInputs"
                />
              </v-col>
              <v-col
                v-if="inputsVisible.max"
                md="6"
                class="pt-0"
              >
                <v-text-field
                  v-model="analysis.standard.max"
                  class="mt-0 pt-0"
                  label="Máximo"
                  hide-details
                  :error="hasError"
                  type="number"
                  @change="changeInputs"
                />
              </v-col>
            </v-row>
            <v-row
              v-if="showRestriction"
              justify="end"
            >
              <v-col
                cols="12"
                class="pt-0 orange--text"
              >
                Restrição
              </v-col>
              <v-col
                v-if="inputsVisible.min"
                md="6"
                class="pt-0"
              >
                <v-text-field
                  v-model="analysis.restriction.min"
                  class="mt-0 pt-0"
                  label="Minimo"
                  hide-details
                  :error="hasRestrictionError"
                  type="number"
                  @change="changeInputs"
                />
              </v-col>
              <v-col
                v-if="inputsVisible.max"
                md="6"
                class="pt-0"
              >
                <v-text-field
                  v-model="analysis.restriction.max"
                  class="mt-0 pt-0"
                  label="Máximo"
                  hide-details
                  :error="hasRestrictionError"
                  type="number"
                  @change="changeInputs"
                />
              </v-col>
            </v-row>
          </template>

          <template v-else>
            <v-row
              align="center"
              justify="end"
            >
              <v-col class="pb-0 pt-0 text-center">
                <span class="overline">{{ analysis.standardDescription || 'Ausência' }}</span>
                <div
                  v-if="false"
                  class="switch-conditions"
                >
                  <span>{{ labelSwitch[0] }}</span>
                  <v-switch
                    v-model="analysis.standard"
                    dense
                    color="indigo lighten-1"
                    hide-details
                    class="switch-analyze"
                    style="margin-bottom: 9px !important"
                    @change="changeInputs"
                  />
                  <span>{{ labelSwitch[1] }}</span>
                </div>
              </v-col>
            </v-row>
          </template>
        </v-form>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<style lang="scss">
.analyze-card {
  border: 1px solid  !important;
  border-radius: 10px !important;
  height: 100% !important;

  .switch-analyze {
    margin-top: 10px !important;
    margin-bottom: 5px !important;
    padding-left: 4px !important;
  }

  .switch-conditions {
    display: flex;
    align-items: center;
    font-family: Roboto, Arial, sans-serif;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.6);
  }

  .unit {
    font-family: Roboto, Arial, sans-serif !important;
    font-size: 0.9rem !important;
    margin-right: 3px;
    color: #9e9e9e;
  }
}
</style>

<script>
import _ from "lodash";

export default {
  name: "AnalysiCard",
  props: {
    analysis: {
      defatul: () => {
        return {
          param: "",
          text: "",
          show: true,
          standard: false,
          units: [],
        };
      },
    },
    dependency: {
      type: String,
      default: '',
    },
    showRestriction: Boolean,
    disabled: Boolean
  },
  data() {
    return {
      valid: true,

      inputsVisible: {
        max: true,
        min: true,
      },

      selectedUnit: {
        description: null,
        unit: null,
      },

      menu: false,
    };
  },
  computed: {
    labelSwitch() {
      if (_.includes(["neutralizantes", "conservantes", "reconstituintes"], this.analysis.param)) {
        return ["Ausênte", "Presente"];
      }

      if (_.includes(["sensorial"], this.analysis.param)) {
        return ["caracte.", "N. caracte."];
      }

      return ["Negativo", "Positivo"];
    },
    units() {
      if (_.has(this.analysis, "units")) {
        return this.analysis.units;
      }

      return [];
    },
    hasError() {

      if (this.inputsVisible.max && this.inputsVisible.min) {
        return parseFloat(this.analysis.standard.max) < parseFloat(this.analysis.standard.min);
      }

      return false;
    },

    hasRestrictionError() {

      if (this.showRestriction && this.inputsVisible.max && this.inputsVisible.min) {
        return parseFloat(this.analysis.restriction.max) < parseFloat(this.analysis.restriction.min);
      }

      return false;
    },
  },
  watch: {
    valid() {
      this.$emit("onValidation", { valid: this.valid });
    },
    hasError() {
      this.$emit("onValidation", { valid: this.hasError || this.hasRestrictionError });
    },
    hasRestrictionError() {
      this.$emit("onValidation", { valid: this.hasError || this.hasRestrictionError });
    },
  },
  created() {
    this.loadStandardInput();
  },
  mounted() {
    this.validate();

    if (_.has(this.analysis, "units")) {
      this.selectedUnit = _.find(this.analysis.units, (u) => u.selected);
    }
  },
  methods: {
    /**
     * Trava evento de change dos inputs
     * @void
     */
    changeInputs() {
      this.$emit("analysis:update", this.analysis);
    },

    /**
     * Verifica qual tipo de input deve ser exibido
     * @void
     */
    loadStandardInput() {
      if (typeof this.analysis.standard === "boolean") {
        this.inputsVisible = {
          max: false,
          min: false,
        };
        return true;
      }

      if (!_.has(this.analysis.standard, "max")) {
        this.inputsVisible.max = false;
      }

      if (!_.has(this.analysis.standard, "min")) {
        this.inputsVisible.min = false;
      }
    },

    validate() {
      this.$refs.form.validate();
    },

    reset() {
      this.$refs.form.reset();
    },

    resetValidation() {
      this.$refs.form.resetValidation();
    },

    /**
     * @void
     * Trata seleção da unidate
     */
    onSelectUnit(unit) {
      if (_.isEmpty(unit)) {
        return false;
      }

      this.analysis.units = this.analysis.units.map((uni) => {
        // marca na prop de analise qual unidade foi selecionada
        if (uni.description === unit.description) {
          return {
            ...uni,
            selected: true,
          };
        }

        return {
          ...uni,
          selected: false,
        };
      });

      // atribui a unite selecionada para flag
      this.selectedUnit = unit;
      this.changeInputs();
    },
  },
};
</script>
