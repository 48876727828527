<template>
  <div>
    <silo-card
      :silo="silo"
      :show-available="validateSiloVol"
      @click="showTransferInput"
    >
      <div
        v-for="tank in transferring"
        :key="tank.index"
        class="input-silo-tank-transfer-row"
      >
        <masked-input
          v-model.number="tank.transferring"
          :label="tank.plate ? `${tank.label} (${tank.plate}):` : `${tank.label}:`"
          :mask="NumberMask"
          :error="overlimit"
          :class="{
            'mt-3': true,
            'input-silo-tank-transfer': true,
            'warning--text': !validateSiloVol && overlimit
          }"
          dense
          filled
          return-unmasked
          hide-details
        >
          <template slot="append">
            <v-btn
              icon
              @click.stop="removeTankToUnload(tank)"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </template>
        </masked-input>
      </div>
    </silo-card>
  </div>
</template>

<style lang="scss">
  .input-silo-tank-transfer-row {

    .warning--text > .v-input__control > .v-input__slot > .v-text-field__slot > label  {
      color: #fb8c00 !important;
      caret-color: #fb8c00 !important;
    }

  }
</style>

<script>
import _ from "lodash";
import MaskedInput from "@/Support/Components/MaskedInput.vue";
import SiloCard from "@/Domains/Industry/ProductionOrder/Components/SiloCard.vue";

export default {

  name: 'SiloTankInfo',

  components: {
    MaskedInput,
    SiloCard,
  },

  props: {
    silo: {
      type: Object,
      default: () => ({
        label: '',
        capacity: 0,
        vol: 0,
        discarded: false,
        transfers: [],
      }),
    },
    tanks: {
      type: Array,
      default: () => ([]),
    },
    availableVol: {
      type: Number,
    },
    measuredVol: {
      type: Number,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    overlimit: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      showTextInput: false,

      transferring: [],

      // Mascara usada nos damais campos numéricos
      NumberMask: {
        mask: 'num',
        blocks: {
          num: {
            mask: Number,
            scale: 0,
            max: 999000,
          },
        },
      },
    }
  },

  computed: {

    tanksToUnload() {
      return this.tanks.filter(tank => ['TO_UNLOAD', 'TO_UNLOAD_RESTRICT'].includes(tank.status));
    },

    measurementParam() {
      return this.$store.state.settings.plataforma ? this.$store.state.settings.plataforma.medicao_descarga_plataforma : 'balanca';
    },

    validateSiloVol() {
      return this.$store.state.settings.gerais.validar_saldo_silo;
    },

  },

  watch: {

    transferring: {
      deep: true,

      handler(newValue) {
        return this.$emit('onTankTransferring', {
          silo: this.silo,
          transferring: newValue,
        });
      },
    },

  },

  methods: {

    showTransferInput() {

      if (['vazao', 'medidor'].includes(this.measurementParam) &&  parseInt(this.measuredVol) === 0 ) {
        this.$snotify.error("Informe o volume de medidos", "Atenção");

        return;
      }

      // Caso o input já esteja sendo exibido, oculta ele e volta o valor disponível
      if (this.showTextInput) {

        return;
      }

      // Caso a transferência para multiplos silos esteja desabilitada, não deve abrir um proximo silo para transferência
      if (this.disabled) {

        return;
      }

      if (this.silo.vol >= this.silo.capacity && this.validateSiloVol) {
        this.$snotify.warning("Não é possível transferência para silo acima da capacidade!", "Atenção");

        return;
      }

      this.showTextInput = true;
      this.transferring = this.tanksToUnload.filter(tank => tank.availableVolToTransfer > 0).map(tank => {
        return {
          ...tank,
          transferring: tank.availableVolToTransfer,
        };
      });
    },

    hideTransferInput() {
      this.showTextInput = false;
      this.transferring = [];
    },

    removeTankToUnload(removedTank) {
      this.transferring = this.transferring.filter(tank => removedTank.index !== tank.index);

      if (_.isEmpty(this.transferring)) {
        return this.hideTransferInput();
      }
    },

  },

}
</script>
