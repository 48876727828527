<template>
  <v-container>
    <v-row>
      <v-col
        cols="3"
        class="py-0"
      >
        <v-date-range-picker
          v-model="range"
          :ranges="ranges"
          label="Período"
          dark
          dense
          :disabled="isReady || isLoading || isSaving || isHistory"
          :rules="[v => !!v || 'Campo obrigatório!']"
        />
      </v-col>
      <v-col
        cols="3"
        class="py-0"
      >
        <v-autocomplete
          v-model="originProducer"
          label="Produtor origem *"
          prepend-inner-icon="person"
          :items="producers"
          item-value="id"
          item-text="name"
          return-object
          dark
          dense
          filled
          clearable
          :filter="(producer, queryText, itemText) => (`${producer.code || ''} - ${producer.name.toLocaleLowerCase()}`).indexOf(queryText.toLocaleLowerCase()) > -1"
          :disabled="isReady || isLoading || isSaving || isHistory"
          :rules="[v => !!v || 'Campo obrigatório!']"
        >
          <template #item="data">
            <v-list-item-content>
              <v-list-item-title>
                {{ data.item.name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <span
                  v-if="data.item.code"
                  class="mx-1"
                >
                  {{ data.item.code }}
                </span>
                <v-chip
                  v-if="data.item.status && !['APROVADO', 'ATIVO'].includes(data.item.status)"
                  x-small
                  color="orange"
                >
                  {{ data.item.status }}
                </v-chip>
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="3"
        class="py-0"
      >
        <v-autocomplete
          v-model="destinationProducer"
          label="Produtor destino"
          prepend-inner-icon="person"
          :items="producers"
          item-value="id"
          item-text="name"
          return-object
          dark
          dense
          filled
          clearable
          :filter="(producer, queryText, itemText) => (`${producer.code || ''} - ${producer.name.toLocaleLowerCase()}`).indexOf(queryText.toLocaleLowerCase()) > -1"
          :disabled="isLoading || isSaving || isHistory"
        >
          <template #item="data">
            <v-list-item-content>
              <v-list-item-title>
                {{ data.item.name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <span
                  v-if="data.item.code"
                  class="mx-1"
                >
                  {{ data.item.code }}
                </span>
                <v-chip
                  v-if="data.item.status && !['APROVADO', 'ATIVO'].includes(data.item.status)"
                  x-small
                  color="orange"
                >
                  {{ data.item.status }}
                </v-chip>
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="3"
        class="py-0"
      >
        <v-btn
          v-if="!isReady"
          block
          elevation="1"
          x-large
          dark
          color="blue lighten-1"
          :disabled="!originProducer.id || isHistory"
          :loading="isLoading"
          @click="search"
        >
          Buscar Dados
          <v-icon
            right
            dark
            size="20"
          >
            send
          </v-icon>
        </v-btn>
        <v-btn
          v-else
          block
          elevation="1"
          x-large
          dark
          color="red lighten-2"
          :loading="isSaving"
          :disabled="isHistory"
          @click="reset"
        >
          Cancelar
          <v-icon
            right
            dark
            size="20"
          >
            clear
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row style="position:relative">
      <v-overlay
        :value="!isReady || isLoading || isSaving"
        absolute
      >
        <v-card-text v-if="isLoading || isSaving">
          {{ isLoading ? 'Carregando...' : 'Salvando...' }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
        <v-alert
          v-else
          dense
          light
          prominent
          colored-border
          border="left"
          elevation="2"
          type="info"
        >
          Preencha os campos acima para iniciar a busca
        </v-alert>
      </v-overlay>
      <v-col cols="3">
        <v-card
          dark
          color="transparent"
        >
          <v-card-title>
            Itens
            <v-spacer />
          </v-card-title>
          <v-list
            color="rgba(255, 255, 255, 0.08)"
          >
            <v-list-item-group
              v-model="window"
              color="white"
              mandatory
              class="text-left"
            >
              <v-list-item
                v-for="(item, key) of items"
                :key="key"
                :disabled="!item.items.length"
              >
                <v-list-item-action>
                  <v-checkbox
                    v-model="selecteds"
                    :value="key"
                    :disabled="!item.items.length || isHistory"
                  />
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-badge
                    v-if="item.items.length"
                    inline
                    color="light-blue"
                    :content="item.items.length"
                  />
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <v-card-text>
            <v-btn
              block
              elevation="2"
              x-large
              color="green lighten-1"
              :disabled="!hasDataToTranfer || isHistory"
              :loading="isSaving"
              @click="save"
            >
              Transferir
              <v-icon
                right
                dark
                size="20"
              >
                cloud_done
              </v-icon>
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="9">
        <v-window
          v-model="window"
          class="elevation-1 pt-0"
          vertical
        >
          <v-window-item
            v-for="(item, idx) of items"
            :key="`card-${idx}`"
          >
            <v-card
              dark
              color="transparent"
            >
              <v-card-title>
                {{ item.title }}
                <v-spacer />
              </v-card-title>
              <data-table
                :headers="item.headers"
                :items="item.items"
                dark
              />
            </v-card>
          </v-window-item>
        </v-window>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment';

import VisitsService from "@/Domains/Visits/Services/VisitsService.js";

import VDateRangePicker from '@/Support/Components/VDateRangePicker.vue';
import PersonAutocompleteFilter from '@/Support/Components/Filters/PersonAutocompleteFilter.vue';

const visitTypes = (new VisitsService()).types;

export default {
  name: 'ownership-change',

  components: {
    VDateRangePicker,
    PersonAutocompleteFilter
  },

  props: {
    transferId: String
  },

  data() {
    return {
      isLoading: false,
      isSaving: false,
      isReady: false,

      window: 0,

      ranges: {
        'Este mês': [
          moment().startOf('month').format('YYYY-MM-DD'),
          moment().endOf('month').format('YYYY-MM-DD')
        ],
        'Mês anterior': [
          moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
          moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
        ],
      },

      range: [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
      originProducer: {},
      destinationProducer: {},
      selecteds: [],

      items: {
        collects: {
          title: 'Coletas',
          headers: [
            { text: 'Data Coleta', value: 'data', formatter: value => this.formatDate(value, 'DD/MM/YYYY'), drag: false },
            { text: 'Hora', align: 'center', value: 'hora_saida', drag: false },
            { text: 'Rota', align: 'center', value: 'nome_rota', drag: false },
            { text: 'Motorista', value: 'nome_motorista', drag: false },
            { text: 'Efetivação', value: 'data_hora_descarga', formatter: value => this.formatDate(value, 'DD/MM/YYYY HH:mm'), drag: false },
            { text: 'Temperatura', value: 'temperatura', align: 'center', formatter: value => this.formatNumber(value), drag: false },
            { text: 'Tanque 1', value: 'tanque1', align: 'center', formatter: value => this.formatNumber(value), drag: false },
            { text: 'Tanque 2', value: 'tanque2', align: 'center', formatter: value => this.formatNumber(value), drag: false },
            { text: 'Tanque 3', value: 'tanque3', align: 'center', formatter: value => this.formatNumber(value), drag: false },
            { text: 'Tanque 4', value: 'tanque4', align: 'center', formatter: value => this.formatNumber(value), drag: false },
            { text: 'Tanque 5', value: 'tanque5', align: 'center', formatter: value => this.formatNumber(value), drag: false },
            { text: 'Tanque 6', value: 'tanque6', align: 'center', formatter: value => this.formatNumber(value), drag: false },
            { text: 'Tanque 7', value: 'tanque7', align: 'center', formatter: value => this.formatNumber(value), drag: false },
            { text: 'Total (L)', value: 'quantidade_coleta', align: 'center', formatter: value => this.formatNumber(value), drag: false },
          ],
          items: []
        },
        visits: {
          title: 'Visitas',
          headers: [
            { text: 'Data Visita', value: 'data_visita', formatter: value => this.formatDate(value, 'DD/MM/YYYY'), drag: false },
            { text: 'Início', value: 'hora_chegada', align: 'center', formatter: value => moment(value, 'HH:mm:ss').format('HH:mm'), drag: false },
            { text: 'Fim', value: 'hora_saida', align: 'center', formatter: value => moment(value, 'HH:mm:ss').format('HH:mm'), drag: false },
            { text: 'Técnico', value: 'nome_tecnico', drag: false },
            { text: 'Tipo', value: 'tipo', formatter: value => (value || []).map(type => visitTypes[type]).join(', '), drag: false },
            { text: 'Observação', value: 'observacao', drag: false },
          ],
          items: []
        },
        analysis: {
          title: 'Análises',
          headers: [
            { text: 'Data', value: 'data', formatter: value => this.formatDate(value, 'DD/MM/YYYY'), drag: false },
            { text: 'Amostra', value: 'numero_amostra', align: 'center', drag: false },
            { text: 'CCS', value: 'ccs', align: 'center', formatter: value => this.formatNumber(value), drag: false },
            { text: 'CPP', value: 'cbt', align: 'center', formatter: value => this.formatNumber(value), drag: false },
            { text: 'Caseína', value: 'caseina', align: 'center', formatter: value => this.formatNumber(value), drag: false },
            { text: 'Gordura', value: 'gordura', align: 'center', formatter: value => this.formatNumber(value), drag: false },
            { text: 'Uréia', value: 'ureia', align: 'center', formatter: value => this.formatNumber(value), drag: false },
            { text: 'Lactose', value: 'lactose', align: 'center', formatter: value => this.formatNumber(value), drag: false },
            { text: 'Proteina', value: 'proteina', align: 'center', formatter: value => this.formatNumber(value), drag: false },
            { text: 'ESD', value: 'esd', align: 'center', formatter: value => this.formatNumber(value), drag: false },
            { text: 'S.T.', value: 'solidos_totais', align: 'center', formatter: value => this.formatNumber(value), drag: false },
          ],
          items: []
        },
        samples: {
          title: 'Amostras',
          headers: [
            { text: 'Data', value: 'data', formatter: value => this.formatDate(value, 'DD/MM/YYYY'), drag: false },
            { text: 'Amostra', value: 'numero_amostra', drag: false },
            { text: 'Tipo', value: 'tipo', drag: false },
            { text: 'Motorista/Usuário', value: 'nome_usuario', drag: false },
            { text: 'Resultado', value: 'positivo', drag: false },
            { text: 'Avisado', value: 'avisado', align: 'start', sortable: false, formatter: value => value ? 'Sim' : 'Não' },
            { text: 'Observação', value: 'observacao', drag: false },
          ],
          items: []
        },
        accounts: {
          title: 'Contas',
          headers: [
            { text: 'Data Emissão', value: 'data_emissao', formatter: value => this.formatDate(value, 'DD/MM/YYYY'), drag: false },
            { text: 'Tipo', value: 'tipo', formatter: value => this.getAccountType(value), drag: false },
            { text: 'Descrição', value: 'descricao', drag: false },
            { text: 'Fornecedor', value: 'nome_fornecedor', drag: false },
            { text: 'Parcelas', value: 'parcela_quantidade', drag: false },
            { text: 'Valor', value: 'valor', align: 'center', formatter: value => this.formatCurrency(value), drag: false },
            { text: 'Saldo', value: 'saldo', align: 'center', formatter: value => this.formatCurrency(value), drag: false },
          ],
          items: []
        },
        exams: {
          title: 'Exames Brucelose / Tuberculose',
          headers: [
            { text: 'Tipo', value: 'tipo', drag: false },
            { text: 'Data Exame', value: 'data', align: 'center', formatter: value => this.formatDate(value, 'DD/MM/YYYY'), drag: false },
            { text: 'Quantidade de Animais', align: 'center', value: 'quantidade_animais', drag: false },
            { text: 'Data Venc.', value: 'data_vencimento', align: 'center', formatter: value => this.formatDate(value, 'DD/MM/YYYY'), drag: false },
          ],
          items: []
        },
      },

      producers: [],
    };
  },

  computed: {
    hasDataToTranfer() {
      if (!this.destinationProducer.id) {
        return false
      }

      if (this.originProducer.id === this.destinationProducer.id) {
        return false
      }

      return this.selecteds.length > 0
    },

    isHistory() {
      return !!this.transferId;
    }
  },

  mounted() {
    if (this.transferId) {
      this.loadTransferHistory();
    }

    this.loadProducers();
  },

  methods: {
    async loadTransferHistory() {
      try {
        this.isLoading = true;

        const { data } = await this.$axios.get(`/producer/transfer-data/history/${this.transferId}`);

        this.range = [data.transferencia.data_inicio, data.transferencia.data_fim];

        this.originProducer = {
          id: data.transferencia.produtor_origem.id_pessoa,
          name: data.transferencia.produtor_origem.nome
        };

        this.destinationProducer = {
          id: data.transferencia.produtor_destino.id_pessoa,
          name: data.transferencia.produtor_destino.nome
        };

        this.items.collects.items = data.coletas;
        this.items.visits.items = data.visitas;
        this.items.analysis.items = data.analises;
        this.items.samples.items = data.amostras;
        this.items.accounts.items = data.contas;
        this.items.exams.items = data.exames;

        if (data.coletas.length > 0) {
          this.selecteds.push('collects');
        }
        if (data.visitas.length > 0) {
          this.selecteds.push('visits');
        }
        if (data.analises.length > 0) {
          this.selecteds.push('analysis');
        }
        if (data.amostras.length > 0) {
          this.selecteds.push('samples');
        }
        if (data.contas.length > 0) {
          this.selecteds.push('accounts');
        }
        if (data.exames.length > 0) {
          this.selecteds.push('exams');
        }

        this.isReady = true;
      } catch (err) {
        console.warn(err);
      } finally {
        this.isLoading = false;
      }
    },

    async loadProducers() {
      try {
        const { data } = await this.$axios.post(`/pessoa/listaProdutores`);

        if (_.isString(data)) {
          throw data;
        }

        this.producers = data.map(producer => {
          return {
            id: producer.id_pessoa,
            code: producer.codigo_laticinio,
            name: producer.nome,
            status: producer.status,
          };
        });
      } catch (error) {
        console.log(error);
        this.$snotify.error("Oops, ocorreu um erro ao carregar os produtores!", "Atenção");
      }
    },

    async search() {
      try {
        this.isLoading = true;
        const [startDate, endDate] = this.range;
        const { data } = await this.$axios.get('/producer/transfer-data', {
          params: {
            producer_id: this.originProducer.id,
            start_date: startDate,
            end_date: endDate,
          }
        });

        this.items.collects.items = data.coletas;
        this.items.visits.items = data.visitas;
        this.items.analysis.items = data.analises;
        this.items.samples.items = data.amostras;
        this.items.accounts.items = data.contas;
        this.items.exams.items = data.exames;

        this.isReady = true;
      } catch (err) {
        console.warn(err);
      } finally {
        this.isLoading = false;
      }
    },

    async save() {
      if (!(await this.$root.$confirm(
        'Atenção',
        `Deseja realmente transferir os dados selecionados?
        <br><br>
        - De: <b>${this.originProducer.name}</b>
        <br>
        - Para: <b>${this.destinationProducer.name}</b>
        <br><br>
        - Coletas: <b>${this.selecteds.includes('collects') ? this.items.collects.items.length : 0}</b>
        <br>
        - Visitas: <b>${this.selecteds.includes('visits') ? this.items.visits.items.length : 0}</b>
        <br>
        - Análises: <b>${this.selecteds.includes('analysis') ? this.items.analysis.items.length : 0}</b>
        <br>
        - Amostras: <b>${this.selecteds.includes('samples') ? this.items.samples.items.length : 0}</b>
        <br>
        - Contas: <b>${this.selecteds.includes('accounts') ? this.items.accounts.items.length : 0}</b>
        <br>
        - Exames: <b>${this.selecteds.includes('exams') ? this.items.exams.items.length : 0}</b>
        <br><br>
        Esta ação não poderá ser revertida!
        <br><br>`,
        { color: 'red', token: 'CONFIRMAR' }
      ))
      ) {
        return;
      }
      try {
        this.isSaving = true;

        const [startDate, endDate] = this.range;

        const payload = {
          origin_producer_id: this.originProducer.id,
          destination_producer_id: this.destinationProducer.id,
          start_date: startDate,
          end_date: endDate,
          transfers: {
            coletas: this.selecteds.includes('collects') ? this.items.collects.items.map(o => o.id_coleta) : [],
            visitas: this.selecteds.includes('visits') ? this.items.visits.items.map(o => o.id_visita) : [],
            analises: this.selecteds.includes('analysis') ? this.items.analysis.items.map(o => o.id_controle_leiteiro) : [],
            amostras: this.selecteds.includes('samples') ? this.items.samples.items.map(o => o.id_coletas_amostra) : [],
            contas: this.selecteds.includes('accounts') ? this.items.accounts.items.map(o => o.id_titulo) : [],
            exames: this.selecteds.includes('exams') ? this.items.exams.items.map(o => o.id) : [],
          }
        };

        await this.$axios.post('/producer/transfer-data', payload);

        this.reset();
        this.$snotify.success("Sucesso, dados transferidos!");
      } catch (err) {
        console.warn(err);
      } finally {
        this.isSaving = false;
      }
    },

    reset() {
      this.isReady = false;
      this.isLoading = false;
      this.isSaving = false;
      this.window = 0;
      this.selecteds = [];
      this.range = [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')];
      this.originProducer = {};
      this.destinationProducer = {};
      for (const key in this.items) {
        this.items[key].items = [];
      }
    },

    getAccountType(type) {
      const types = {
        'CONTA_A_PAGAR': 'Conta a Pagar',
        'CONTA_A_RECEBER': 'Conta a Receber',
        'VALE': 'Vale',
        'DESCONTO': 'Desconto',
        'CONVENIO': 'Convênio',
        'ANTIBIOTICO': 'Desconto (Antibiótico)',
        'NORMATIVA': 'Desconto (Amostra)',
        'FINANCEIRO': 'Desconto (Financeiro)',
      };

      return types[type] || type;
    },

    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value),
    formatNumber: (value) => !value ? '' : new Intl.NumberFormat('pt-BR').format(value),
    formatDate: (value, format) => !value ? '' : moment(value).format(format)
  }

}
</script>
