<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col cols="12">
        <h2
          class="menu-header white--text"
        >
          Critérios de Bonificação
        </h2>
        <bonus-product-name :raw-material-id="rawMaterialId" />
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-tabs
        v-model="tab"
        dark
        centered
        background-color="transparent"
        class="transparent"
        @change="onTabChange"
      >
        <v-tab href="#general-tab">
          Tabelas Geral
        </v-tab>
        <v-tab href="#route-tab">
          Tabelas por Rota
        </v-tab>
        <v-tab
          href="#planning-tab"
          :disabled="tab !== 'planning-tab'"
        >
          Planejamento de Bonificação
        </v-tab>
        <v-tab href="#extra-tab">
          Bonificações Extras
        </v-tab>
        <v-tab-item value="general-tab">
          <planning-history
            v-if="tab === 'general-tab'"
            ref="history"
            :raw-material-id="rawMaterialId"
            @newPlanning="onNewPlanning"
            @selectPlanning="onHistorySelectPlanning"
          />
        </v-tab-item>

        <v-tab-item value="route-tab">
          <route-planning-history
            v-if="tab === 'route-tab'"
            :raw-material-id="rawMaterialId"
            @selectPlanning="onRouteSelectPlanning"
          />
        </v-tab-item>

        <v-tab-item value="planning-tab">
          <bonus-planning
            v-if="tab === 'planning-tab'"
            ref="planning"
            v-model="planning.id"
            :route="planning.route"
            :raw-material-id="planning.rawMaterialId"
            :is-view-mode.sync="planning.isViewMode"
          />
        </v-tab-item>
        <v-tab-item value="extra-tab">
          <bonus-extra />
        </v-tab-item>
      </v-tabs>
    </v-row>
  </v-container>
</template>

<script>
import PlanningHistory from "@/Domains/Producer/Bonus/BonusCriteria/Views/PlanningHistory.vue";
import RoutePlanningHistory from "@/Domains/Producer/Bonus/BonusCriteria/Views/RoutePlanningHistory.vue";
import BonusPlanning from "@/Domains/Producer/Bonus/BonusCriteria/Views/BonusPlanning.vue";
import BonusExtra from "@/Domains/Producer/Bonus/BonusCriteria/Views/BonusExtra.vue";
import BonusProductName from "@/Domains/Producer/Bonus/BonusSpreadsheet/Components/BonusProductName.vue";

export default {
  name: "bonus-criteria",

  components: {
    PlanningHistory,
    RoutePlanningHistory,
    BonusPlanning,
    BonusExtra,
    BonusProductName,
  },

  data() {
    return {
      rawMaterialId: null,
      tab: null,
      planning: {
        id: null,
        route: null,
        rawMaterialId: null,
        isViewMode: true,
      }
    };
  },

  mounted() {
    this.rawMaterialId = this.$store.state.settings?.gerais?.id_materia_prima_principal
  },

  methods: {
    onTabChange() {
      this.planning.id = null;
    },

    onNewPlanning() {
      this.onSelectPlanning({})
    },

    onHistorySelectPlanning(id, isViewMode) {
      this.onSelectPlanning({ id, isViewMode })
    },

    onRouteSelectPlanning(id, route) {
      this.onSelectPlanning({ id, route })
    },

    onSelectPlanning({ id = null, route = {}, isViewMode = false }) {
      this.planning.id = id;
      this.planning.route = route || {};
      this.planning.rawMaterialId = this.rawMaterialId;
      this.planning.isViewMode = isViewMode;
      this.tab = 'planning-tab';
    },
  },
};
</script>
