<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="pt-0"
  >
    <v-row>
      <v-col cols="12">
        <h2 class="menu-header white--text">
          Análises De Produtor
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>

      <analysis-import-result
        v-model="importResult.show"
        :successes="importResult.successes"
        :failures="importResult.failures"
      />

      <v-col cols="12">
        <v-card
          dark
          color="transparent"
        >
          <v-card-title>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
                class="py-0"
              >
                <v-date-range-picker
                  v-model="filters.dateRange"
                  dark
                  @change="loadReport"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
                class="py-0"
              >
                <v-select
                  v-model="filters.producerStatus"
                  dark
                  filled
                  label="Produtores"
                  hide-details
                  clearable
                  :items="[
                    { text:'Ativos', value:'ATIVO' },
                    { text:'Suspenso', value:'SUSPENSO' },
                    { text:'Prospectado', value:'PROSPECTADO' }
                  ]"
                  @change="loadReport"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
                class="py-0"
              >
                <v-text-field
                  v-model="filters.search"
                  filled
                  hide-details
                  prepend-inner-icon="search"
                />
              </v-col>
            </v-row>
          </v-card-title>

          <data-table
            ref="report"
            :headers="headers"
            :items="filteredItems"
            :expanded="expanded"
            class="elevation-1"
            disable-filtering
            multi-sort
            dark
            show-custom-group
            :show-expand="showExpand"
            item-key="id_analise"
          >
            <template #[`item.nome_produtor`]="{ item }">
              {{ item.nome_produtor }}
              <v-icon
                v-if="item.id_condominio || item.tipo_condominio || item.id_cooperativa"
                color="rgba(255, 255, 255, 0.7)"
              >
                groups
              </v-icon>
            </template>
            <template #[`item.ccs`]="{ value }">
              {{ formatNumber(value) }}
            </template>
            <template #[`item.cbt`]="{ value }">
              {{ formatNumber(value) }}
            </template>
            <template #[`item.caseina`]="{ value }">
              {{ formatNumber(value) }}
            </template>
            <template #[`item.gordura`]="{ value }">
              {{ formatNumber(value) }}
            </template>
            <template #[`item.ureia`]="{ value }">
              {{ formatNumber(value) }}
            </template>
            <template #[`item.lactose`]="{ value }">
              {{ formatNumber(value) }}
            </template>
            <template #[`item.proteina`]="{ value }">
              {{ formatNumber(value) }}
            </template>
            <template #[`item.esd`]="{ value }">
              {{ formatNumber(value) }}
            </template>
            <template #[`item.solidos_totais`]="{ value }">
              {{ formatNumber(value) }}
            </template>

            <template #[`item.compoe_media`]="{ value }">
              <v-chip small>
                {{ value ? 'Sim' : 'Não' }}
              </v-chip>
            </template>

            <template #[`item.data-table-expand`]="{ item, expand, isExpanded }">
              <v-btn
                v-if="item.produtores?.length > 1"
                icon
                link
                transition="fab-transition"
                @click="expand(!isExpanded)"
              >
                <v-icon>
                  {{ isExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
                </v-icon>
              </v-btn>
            </template>

            <template #expanded-item="{ headers, item }">
              <td
                :colspan="headers.length"
                class="pa-0 text-center"
              >
                <v-data-table
                  :headers="headers"
                  :items="item.produtores"
                  :items-per-page="-1"
                  dense
                  hide-default-footer
                  class="elevation-1 ma-3"
                >
                  <template #[`item.data`]="{ value }">
                    {{ formatDate(value, 'DD/MM/YYYY') }}
                  </template>
                  <template #[`item.ccs`]="{ value }">
                    {{ formatNumber(value) }}
                  </template>
                  <template #[`item.cbt`]="{ value }">
                    {{ formatNumber(value) }}
                  </template>
                  <template #[`item.caseina`]="{ value }">
                    {{ formatNumber(value) }}
                  </template>
                  <template #[`item.gordura`]="{ value }">
                    {{ formatNumber(value) }}
                  </template>
                  <template #[`item.ureia`]="{ value }">
                    {{ formatNumber(value) }}
                  </template>
                  <template #[`item.lactose`]="{ value }">
                    {{ formatNumber(value) }}
                  </template>
                  <template #[`item.proteina`]="{ value }">
                    {{ formatNumber(value) }}
                  </template>
                  <template #[`item.esd`]="{ value }">
                    {{ formatNumber(value) }}
                  </template>
                  <template #[`item.solidos_totais`]="{ value }">
                    {{ formatNumber(value) }}
                  </template>

                  <template #[`item.compoe_media`]="{ value }">
                    <v-chip small>
                      {{ value ? 'Sim' : 'Não' }}
                    </v-chip>
                  </template>
                </v-data-table>
              </td>
            </template>

            <template #[`footer.prepend`]>
              <div class="text-caption text-left ml-4">
                Total de
                <v-chip
                  small
                  class="mx-2 mb-0"
                >
                  {{ items.length }}
                </v-chip>
                registros
              </div>
            </template>
          </data-table>
        </v-card>
      </v-col>
    </v-row>

    <import-excel-dialog
      v-model="importAnalysis.show"
      title="Importar análises"
      :fields="importFields"
      show-model
      @import="onImportAnalysis"
    />

    <editing-analysis-dialog
      ref="dialog"
      @onSave="onAnalysisSave"
    />

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>
      <v-btn
        fab
        dark
        color="deep-purple darken-1"
        @click="showCreatingAnalysisDialog"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              add
            </v-icon>
          </template>

          Adicionar Analise
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="blue"
        @click="showImportAnalysis()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              system_update_alt
            </v-icon>
          </template>
          Importar Análises
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </v-container>
</template>

<script>
import EditingAnalysisDialog from "@/Domains/Analysis/Producers/Components/EditingAnalysisDialog.vue";
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";
import ImportExcelDialog from '@/Support/Components/ImportExcelDialog.vue';
import AnalysisImportResult from '@/Domains/Analysis/Upload/Components/AnalysisImportResult.vue';
import AnalysisImportMixin from '@/Domains/Analysis/Upload/Mixins/AnalysisImportMixin.js';

import moment from "moment-timezone";
import _ from "lodash";

export default {
  components: {
    VDateRangePicker,
    EditingAnalysisDialog,
    AnalysisImportResult,
    ImportExcelDialog,
  },

  mixins: [AnalysisImportMixin],

  data() {
    return {
      filters: {
        search: '',
        dateRange: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
        producerStatus: null,
      },

      headers: [
        { text: 'Codigo Laticinio', value: 'codigo_laticinio', align: 'center' },
        { text: 'Produtor', value: 'nome_produtor', align: 'start' },
        { text: 'Data', value: 'data', align: 'center', formatter: value => this.formatDate(value, 'DD/MM/YYYY') },
        { text: 'Compõe Média', value: 'compoe_media', align: 'center' },
        { text: 'CCS', value: 'ccs', align: 'center', formatter: value => this.parseNumber(value) },
        { text: 'CPP', value: 'cbt', align: 'center', formatter: value => this.parseNumber(value) },
        { text: 'Caseína', value: 'caseina', align: 'center', formatter: value => this.parseNumber(value) },
        { text: 'Gordura', value: 'gordura', align: 'center', formatter: value => this.parseNumber(value) },
        { text: 'Uréia', value: 'ureia', align: 'center', formatter: value => this.parseNumber(value) },
        { text: 'Lactose', value: 'lactose', align: 'center', formatter: value => this.parseNumber(value) },
        { text: 'Proteina', value: 'proteina', align: 'center', formatter: value => this.parseNumber(value) },
        { text: 'ESD', value: 'esd', align: 'center', formatter: value => this.parseNumber(value) },
        { text: 'S.T.', value: 'solidos_totais', align: 'center', formatter: value => this.parseNumber(value) },
        { text: 'Amostra', value: 'numero_amostra', align: 'center' }
      ],

      items: [],
      expanded: [],
      showExpand: false,

      // Dialogs
      editing: {
        show: false,
        title: '',
        analysis: {},
      },

      importType: 'PRODUTOR',
    };

  },

  computed: {
    filteredItems() {
      if (!this.filters.search) {
        return this.items;
      }

      const search = typeof this.filters.search === 'string' ? this.filters.search.toUpperCase().trim() : null;
      return this.items.filter(item => {
        const hasSearch = !search || JSON.stringify(Object.values(item)).toUpperCase().includes(search);
        return hasSearch;
      });
    },
  },

  methods: {
    showCreatingAnalysisDialog() {
      return this.$refs.dialog.show({
        producer: {},
        analysisAt: moment().format('YYYY-MM-DD'),
        type: 'PRODUCER',
        compoe_media: true,
      });
    },

    async onAnalysisSave(analysis) {

      try {
        let analysisAt = !_.isEmpty(analysis.analysisAt) ? moment(analysis.analysisAt).format('YYYY-MM-DD') : null ;

        const request = {
          tipo: 'PRODUTOR',
          data: analysis.collectedAt,
          id_produtor: analysis.producer.id,
          nome_produtor: analysis.producer.name,
          cbt: analysis.cbt,
          ccs: analysis.ccs,
          gordura: analysis.gordura,
          caseina: analysis.caseina,
          ureia: analysis.ureia,
          lactose: analysis.lactose,
          proteina: analysis.proteina,
          esd: analysis.esd,
          solidos_totais: analysis.solidos_totais,
          numero_amostra: analysis.numero_amostra,
          compoe_media: analysis.compoe_media,
          data_analise: analysis.analyzedAt,
          crioscopia: analysis.crioscopia
        };

        await this.$axios.post(`/analysis`, request);

        this.$refs.dialog.close();

        await this.loadReport();
      } catch (e) {
        console.log(e);

        this.$snotify.error("Oops, ocorreu um erro ao salvar a analise!", "Atenção");
      } finally {
        this.$refs.dialog.close();
      }
    },

    async loadReport() {
      try {
        this.$root.$progressBar.loading();

        const [dateStart, dateEnd] = this.filters.dateRange;

        let { data } = await this.$axios.get(`/analysis`, { params: {
          type: 'PRODUTOR',
          start_date: dateStart,
          end_date: dateEnd,
          producer_status: this.filters.producerStatus
        } });

        const hasGrouping = data.find(item => item.tipo_condominio);

        if (hasGrouping) {
          data = data.map(item => {
            // Adiciona as analises de agrupamento no array
            if (item.tipo_condominio) {
              item.produtores = data.filter(producer => producer.id_condominio === item.id_produtor && producer.data === item.data && producer.numero_amostra.startsWith(item.numero_amostra));
            }
            return item;
          });

          data = data.filter(producer => {
            // Remove da visualização as análises agrupadas anteriormente
            if (producer.id_condominio) {
              return !data.find(grouping => producer.id_condominio === grouping.id_produtor && producer.data === grouping.data && producer.numero_amostra.startsWith(grouping.numero_amostra))
            }
            return true;
          });
        }

        this.items = data;

        this.showExpand = hasGrouping && data.some(item => item.produtores?.length > 1);
        if (this.showExpand) {
          this.expanded = this.items.filter(item => item.produtores?.length > 1);
        }

      } catch (error) {
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    getReportTitle() {
      const [startDate, endDate] = this.filters.dateRange;
      return `Análises de produtores - ${moment(startDate || moment()).format('DD.MM')} - ${moment(endDate || moment()).format('DD.MM')}`;
    },

    print() {
      const title = this.getReportTitle();
      this.$refs.report.print(null, title);
    },

    exportExcel() {
      const filename = this.getReportTitle();
      this.$refs.report.exportExcel(null, filename);
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
    formatNumber: val => !val ? '-' : new Intl.NumberFormat('pt-BR').format(val),
    parseNumber: val => +String(val) || null,
  },

}
</script>
