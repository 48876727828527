<template>
  <div>
    <v-row>
      <v-col cols="12">
        <card-title-settings class="mb-3">
          Parâmetros da Plataforma (descarregamento)
        </card-title-settings>
        <v-card
          dark
          outlined
          color="rgba(255, 255, 255, 0.08)"
        >
          <v-card-text class="pb-0">
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="2"
              >
                <v-select
                  v-model="medicao_descarga"
                  :items="[
                    { text: 'Balança', value: 'balanca' },
                    { text: 'Medidor de Vazão', value: 'vazao' },
                    { text: 'Medidor (MilkUp)', value: 'medidor' },
                    { text: 'Vale', value: 'vale' },
                  ]"
                  label="Medição na descarga:"
                />
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="2"
              >
                <v-select
                  v-model="tipo_tolerancia_medidos"
                  :items="[
                    { text: 'Descarga', value: 'DESCARGA' },
                    { text: 'Percentual', value: 'PERCENTUAL' },
                    { text: 'Produtor', value: 'PRODUTOR' },
                  ]"
                  label="Tipo de tolerância (Medidos)"
                />
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="2"
              >
                <v-text-field
                  v-if="tipo_tolerancia_medidos === 'DESCARGA'"
                  v-model="tolerancia_medidos_descarga"
                  label="Tolerância por descarga (Litros):"
                  type="number"
                  placeholder=" "
                />
                <masked-text-field
                  v-else-if="tipo_tolerancia_medidos === 'PERCENTUAL'"
                  v-model="tolerancia_medidos_percentual"
                  label="Tolerância por descarga (%):"
                  placeholder=" "
                  :mask="{ mask: Number, min: 0, max: 100 }"
                  unmask
                />
                <v-text-field
                  v-else-if="tipo_tolerancia_medidos === 'PRODUTOR'"
                  v-model="tolerancia_medidos_produtor"
                  label="Tolerância por produtor (Litros):"
                  type="number"
                  placeholder=" "
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="2"
              >
                <v-select
                  v-model="considerar_sobras_tolerancia"
                  :items="[
                    { text: 'Sim', value: true },
                    { text: 'Não', value: false },
                  ]"
                  label="Considerar sobras na tolerância:"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="2"
              >
                <v-text-field
                  v-model="tolerancia_dias_recebimento"
                  label="Tolerância de horas para recebimento:"
                  type="number"
                  placeholder=" "
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="2"
              >
                <masked-text-field
                  v-model="volume_maximo_cipagem"
                  label="Volume máximo permitido para cipagem:"
                  type="number"
                  placeholder=" "
                  :mask="{ mask: Number, min: 0, max: 99999999 }"
                  unmask
                />
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="2"
              >
                <v-select
                  v-model="mostra_padroes_analise"
                  :items="[
                    { text: 'Sim', value: true },
                    { text: 'Não', value: false },
                  ]"
                  label="Mostra padrões de análise:"
                />
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="2"
              >
                <v-select
                  v-model="altera_data_efetivacao"
                  :items="[
                    { text: 'Sim', value: true },
                    { text: 'Não', value: false },
                  ]"
                  label="Permite alterar a data de efetivação:"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="2"
              >
                <v-select
                  v-model="justificar_edicao_analise"
                  :items="[
                    { text: 'Sim', value: true },
                    { text: 'Não', value: false },
                  ]"
                  label="Justificativa de edição de análise obrigatória:"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="2"
              >
                <v-select
                  v-model="carga_lacre"
                  :items="[
                    { text: 'Sim', value: true },
                    { text: 'Não', value: false },
                  ]"
                  label="Lacre da Carga:"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="2"
              >
                <v-select
                  v-model="cip"
                  :items="[
                    { text: 'Sim', value: true },
                    { text: 'Não', value: false },
                  ]"
                  label="CIP:"
                />
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="2"
              >
                <v-select
                  v-model="obriga_lancamento_todas_analises_descarga"
                  :items="[
                    { text: 'Sim', value: true },
                    { text: 'Não', value: false },
                  ]"
                  label="Análises obrigatórias para descarga:"
                />
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="2"
              >
                <v-select
                  v-model="bloquear_cip_cargas_nao_finalizadas"
                  :items="[
                    { text: 'Sim', value: true },
                    { text: 'Não', value: false },
                  ]"
                  label="Bloquear CIP de Silo com cargas não finalizadas:"
                />
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="3"
              >
                <masked-text-field
                  v-model="limite_diferenca_medidos_percentual"
                  label="Limite diferença entre medidos e vale por coleta (%):"
                  placeholder=" "
                  :mask="{ mask: Number, min: 0, max: 100 }"
                  unmask
                />
              </v-col>

              <v-col
                v-if="limite_diferenca_medidos_percentual"
                cols="12"
                sm="6"
                md="3"
              >
                <v-select
                  v-model="acao_diferenca_medidos"
                  :items="[
                    { text: 'Notificar', value: 'NOTIFICAR' },
                    { text: 'Bloquear', value: 'BLOQUEAR' },
                  ]"
                  label="Ação limite diferença medidos por coleta:"
                  clearable
                />
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="3"
              >
                <masked-text-field
                  v-model="limite_diferenca_medidos_percentual_spot"
                  label="Limite diferença entre medidos e vale por spot (%):"
                  placeholder=" "
                  :mask="{ mask: Number, min: 0, max: 100 }"
                  unmask
                />
              </v-col>

              <v-col
                v-if="limite_diferenca_medidos_percentual_spot"
                cols="12"
                sm="6"
                md="3"
              >
                <v-select
                  v-model="acao_diferenca_medidos_spot"
                  :items="[
                    { text: 'Notificar', value: 'NOTIFICAR' },
                    { text: 'Bloquear', value: 'BLOQUEAR' },
                  ]"
                  label="Ação limite diferença medidos por spot:"
                  clearable
                />
              </v-col>

              <v-col
                v-if="acao_diferenca_medidos === 'NOTIFICAR' || acao_diferenca_medidos_spot === 'NOTIFICAR'"
                cols="12"
                md="6"
              >
                <v-autocomplete
                  v-model="usuarios_notificacao_limite_medidos"
                  label="Usuários notificação limite dif. medidos:"
                  :items="users"
                  item-value="id_pessoa"
                  item-text="nome"
                  multiple
                  clearable
                  small-chips
                  deletable-chips
                />
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="2"
              >
                <v-select
                  v-model="invoice_number"
                  :items="[
                    { text: 'Sim', value: true },
                    { text: 'Não', value: false },
                  ]"
                  label="Número da Nota é obrigatório?"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card
          dark
          color="transparent"
        >
          <v-card-title class="text-subtitle-1">
            Parâmetros para análise
          </v-card-title>
          <v-data-table
            dark
            :sort-by="'nome'"
            :sort-desc="false"
            :items-per-page="15"
            :headers="[
              { text: 'Matéria-prima', value: 'nome', width: 450 },
              { text: '', value: 'actions', width: 10, align: 'end'},
            ]"
            :items="rawMaterials"
          >
            <template #[`item.actions`]="{ item }">
              <v-btn
                icon
                @click.stop="openParameterizationAnalyze(item)"
              >
                <v-icon> settings_applications </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <card-title-settings class="mb-3">
          Analista Responsável
        </card-title-settings>
        <v-card
          outlined
          color="rgba(255, 255, 255, 0.08)"
        >
          <v-card-text>
            <v-col
              v-if="!assinatura_analista_responsavel.length"
              class="text-center"
              cols="12"
            >
              <v-btn
                outlined
                class="mb-5 mt-5"
                dark
                @click="showFileUpload = !showFileUpload"
              >
                <v-icon>attach_file</v-icon> Adicionar arquivos
              </v-btn>
            </v-col>
            <v-col
              v-for="(file, idx) in assinatura_analista_responsavel"
              :key="idx"
              cols="12"
            >
              <file-viewer
                :value="file"
                :preview-height="165"
                @removeFile="onRemoveFile(idx)"
              />
            </v-col>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <file-upload
      v-model="showFileUpload"
      @insertFiles="onSelectFile"
    />

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green"
        @click="savePlataformSettings()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              save
            </v-icon>
          </template>
          Salvar configuraçõe
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="black darken-1"
        @click="onRecalculateDiscounts()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              currency_exchange
            </v-icon>
          </template>

          Recalcular descontos de Descargas (Frete)
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <parameterization-analyze-dialog
      ref="paramAnalyze"
      :raw-material-id="rawMaterialSelected.id"
      :raw-material-name="rawMaterialSelected.name"
      @onClose="onCloseParameterization"
    />

    <recalculate-platform-discounts-dialog
      ref="platformDiscountsDialog"
    />
  </div>
</template>

<script>
import _ from "lodash";

import FileUpload from "@/Support/Components/FileUpload.vue";
import FileViewer from "@/Support/Components/FileViewer.vue";
import MaskedTextField from "@/Support/Components/MaskedTextField.vue";
import CardTitleSettings from "@/Domains/Settings/Components/General/CardTitleSettings.vue"
import ParameterizationAnalyzeDialog from "@/Domains/Settings/Components/ParameterizationAnalyzeDialog.vue";
import RecalculatePlatformDiscountsDialog from "@/Domains/Settings/Components/RecalculatePlatformDiscountsDialog.vue";

export default {

  components: {
    FileUpload,
    FileViewer,
    MaskedTextField,
    CardTitleSettings,
    ParameterizationAnalyzeDialog,
    RecalculatePlatformDiscountsDialog,
  },

  data() {
    return {
      tipo_tolerancia_medidos: 'DESCARGA',
      tolerancia_medidos_descarga: null,
      tolerancia_medidos_percentual: null,
      tolerancia_medidos_produtor: null,
      considerar_sobras_tolerancia: false,
      tolerancia_dias_recebimento: null,
      volume_maximo_cipagem: null,
      medicao_descarga: null,
      mostra_padroes_analise: true,
      assinatura_analista_responsavel: [],
      altera_data_efetivacao: true,
      justificar_edicao_analise: false,
      carga_lacre: false,
      cip: false,
      obriga_lancamento_todas_analises_descarga: false,
      bloquear_cip_cargas_nao_finalizadas: false,
      limite_diferenca_medidos_percentual: null,
      acao_diferenca_medidos: null,
      usuarios_notificacao_limite_medidos: null,
      invoice_number: false,
      limite_diferenca_medidos_percentual_spot: null,
      acao_diferenca_medidos_spot: null,

      showFileUpload: false,

      rawMaterials: [],

      // flag da matéria prima selecionada
      rawMaterialSelected: {
        id: null,
        name: null,
      },

      users: [],
    };
  },

  mounted() {
    this.loadPlataformSettings();
    this.loadRawMaterial();
    this.loadUsers();
  },

  methods: {
    updateTags() {
      this.$nextTick(() => {
        this.select.push(...this.search.split(","));
        this.$nextTick(() => {
          this.search = "";
        });
      });
    },

    /**
     * carregar configuracoes
     */
    async loadPlataformSettings() {
      try {
        const { data } = await this.$axios.post(`/configuracao/listaConfiguracoesPlataforma`);

        if (_.isEmpty(data)) {
          return false;
        }

        this.tipo_tolerancia_medidos = data.tipo_tolerancia_medidos_plataforma || 'DESCARGA';
        this.tolerancia_medidos_descarga = data.tolerancia_medidos_plataforma;
        this.tolerancia_medidos_percentual = data.tolerancia_medidos_percentual_plataforma;
        this.tolerancia_medidos_produtor = data.tolerancia_medidos_produtor_plataforma;
        this.tolerancia_dias_recebimento = data.tolerancia_dias_recebimento;
        this.volume_maximo_cipagem = data.volume_maximo_cipagem;
        this.considerar_sobras_tolerancia = _.isBoolean(data.considerar_sobras_tolerancia) ? data.considerar_sobras_tolerancia : false;
        this.medicao_descarga = data.medicao_descarga_plataforma || "balanca";
        this.mostra_padroes_analise = _.isBoolean(data.mostra_padroes_analise) ? data.mostra_padroes_analise : true;
        this.justificar_edicao_analise = data.justificar_edicao_analise || false;
        this.assinatura_analista_responsavel = !_.isEmpty(data.assinatura_analista_responsavel) ? data.assinatura_analista_responsavel : [];
        this.carga_lacre = data.carga_lacre;
        this.cip = data.cip;
        this.obriga_lancamento_todas_analises_descarga = data.obriga_lancamento_todas_analises_descarga;
        this.bloquear_cip_cargas_nao_finalizadas = data.bloquear_cip_cargas_nao_finalizadas;
        this.limite_diferenca_medidos_percentual = data.limite_diferenca_medidos_percentual;
        this.acao_diferenca_medidos = data.acao_diferenca_medidos;
        this.usuarios_notificacao_limite_medidos = data.usuarios_notificacao_limite_medidos;
        this.invoice_number = data.invoice_number;
        this.limite_diferenca_medidos_percentual_spot = data.limite_diferenca_medidos_percentual_spot;
        this.acao_diferenca_medidos_spot = data.acao_diferenca_medidos_spot;

      } catch (e) {
        console.error(e);
      }
    },

    /**
     * salvar configuracoes de qualidade
     */
    async savePlataformSettings() {
      try {

        //@Todo ajuste para o novo tão com os items
        const payLoad = {
          configuracoesPlataforma: JSON.stringify([
            {
              tipo_tolerancia_medidos_plataforma: this.tipo_tolerancia_medidos,
              tolerancia_medidos_plataforma: this.tolerancia_medidos_descarga,
              tolerancia_medidos_percentual_plataforma: this.tolerancia_medidos_percentual,
              tolerancia_medidos_produtor_plataforma: this.tolerancia_medidos_produtor,
              considerar_sobras_tolerancia: this.considerar_sobras_tolerancia,
              tolerancia_dias_recebimento: this.tolerancia_dias_recebimento || null,
              volume_maximo_cipagem: this.volume_maximo_cipagem || null,
              medicao_descarga_plataforma: this.medicao_descarga,
              mostra_padroes_analise: this.mostra_padroes_analise,
              assinatura_analista_responsavel: this.assinatura_analista_responsavel,
              altera_data_efetivacao: this.altera_data_efetivacao,
              justificar_edicao_analise: this.justificar_edicao_analise,
              carga_lacre: this.carga_lacre,
              cip: this.cip,
              obriga_lancamento_todas_analises_descarga: this.obriga_lancamento_todas_analises_descarga,
              bloquear_cip_cargas_nao_finalizadas: this.bloquear_cip_cargas_nao_finalizadas,
              limite_diferenca_medidos_percentual: this.limite_diferenca_medidos_percentual,
              acao_diferenca_medidos: this.acao_diferenca_medidos,
              usuarios_notificacao_limite_medidos: this.usuarios_notificacao_limite_medidos,
              invoice_number: this.invoice_number,
              limite_diferenca_medidos_percentual_spot: this.limite_diferenca_medidos_percentual_spot,
              acao_diferenca_medidos_spot: this.acao_diferenca_medidos_spot,
            },
          ]),
        };

        let { data } = await this.$axios.post(
          `/configuracao/salvaConfiguracoesPlataforma`,
          this.$qs.stringify(payLoad)
        );

        if (!_.isObject(data)) {
          throw "PHP error";
        }

        //@todo tratar erro
        if (_.get(data, "codigo") !== 1) {
          throw data.mensagem;
        }

        this.$snotify.success("Informações salvas com sucesso", "Sucesso");
        this.$store.dispatch('updatePlatformSettings');
      } catch (error) {
        console.log(error);
        this.$snotify.error("Erro ao salvar informações do produtor", "Atenção");
      }
    },

    /**
     * Busca item de máteria-prima
     */
    async loadRawMaterial() {
      try {
        const { data } = await this.$axios.get(`/item`, { params: {
          tipo_materia_prima: 'TODOS'
        } });

        this.rawMaterials = data;
      } catch (e) {
        console.warn(e);
      }
    },

    async loadUsers() {
      try {
        const { data } = await this.$axios.post(`/pessoa/listaUsuarioJson`, {
          exibir: 'ATIVOS'
        });

        this.users = data;

      } catch (error) {
        this.$snotify.error('Erro ao carregar usuários', 'Atenção');
        console.warn(error);
      }
    },

    /**
     * Abre o dialog de parâmetrização
     */
    openParameterizationAnalyze(item) {

      this.rawMaterialSelected =  {
        id: item.id_item,
        name: item.nome
      };

      this.$refs.paramAnalyze.openDialog();
    },

    onSelectFile(files) {
      this.assinatura_analista_responsavel = files.map(file => ({
        success: true,
        name: file.name,
        size: file.size,
        url: file.url
      }));
    },

    onRemoveFile(idx) {
      this.assinatura_analista_responsavel.splice(idx, 1);
    },

    /**
     * Trata close do model de parâmetro
     */
    onCloseParameterization() {
      this.rawMaterialSelected =  {
        id: null,
        name: null
      };
    },

    onRecalculateDiscounts() {
      this.$refs.platformDiscountsDialog.show();
    },
  },
};
</script>
