<template>
  <v-card
    flat
    class="card-analysis-settings"
  >
    <span class="ml-2 overline">Parâmetros de análise</span>
    <v-card-text
      class="pa-3"
    >
      <v-row>
        <v-col
          cols="12"
          sm="6"
          lg="2"
          class="pt-0"
        >
          <v-checkbox
            v-model="settingsLocal.showRestriction"
            label="Exibir faixa de restrição"
            single-line
            hide-details
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          lg="5"
          class="pt-0"
        >
          <v-checkbox
            v-if="settingsLocal.showRestriction"
            v-model="settingsLocal.automaticallyMarkOutOfRange"
            label="Marcar automaticamente Restrição ou Descarte se fora da faixa"
            single-line
            hide-details
          />
        </v-col>
        <v-col
          cols="12"
          lg="5"
          class="pt-0"
        >
          <v-text-field
            v-model="search"
            prepend-inner-icon="search"
            label="Procurar análises"
            single-line
            hide-details
          />
        </v-col>
      </v-row>
      <v-form>
        <v-row class="analysis-container">
          <analysis-card
            v-for="analysis in analisisSelected"
            :key="analysis.param"
            :analysis="analysis"
            :show-restriction="settingsLocal.showRestriction"
            @onValidation="onValidation"
          />

          <v-col
            cols="12"
            class="py-3"
          >
            <v-divider />
          </v-col>

          <analysis-card
            v-for="analysis in analysisDefaultAbsent"
            :key="analysis.analysis.param"
            :analysis="analysis.analysis"
            :dependency="analysis.dependencyName"
          />
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<style lang="scss">

.span-type {
  font-family: Roboto, Arial, sans-serif;
  font-size: 1.2rem;
  color: #424242;
}
.analysis-container::-webkit-scrollbar {
  display: none;
}

.analysis-container {
  max-height: calc(100vh - 280px) !important;
  overflow-y: auto;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}
</style>
<script>
import AnalysisCard from "@/Domains/Settings/Components/Analysis/AnalysisCard.vue";

import _ from "lodash";

export default {
  components: {
    AnalysisCard,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    settings: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      search: null,
    };
  },
  computed: {
    analisisSelected() {
      return _.orderBy(this.value
        .map((a) => a.analysis)
        .filter((f) => {

          if (this.search) {
            return _.isObject(f.standard) && _.upperCase(f.text).includes(_.upperCase(this.search)) ;
          }

          return _.isObject(f.standard)  ;
        }), 'param');
    },

    analysisDefaultAbsent() {
      return _.orderBy(this.value
        .filter((f) => {

          if (this.search) {
            return !_.isObject(f.analysis.standard) && _.upperCase(f.analysis.text).includes(_.upperCase(this.search)) ;
          }

          return !_.isObject(f.analysis.standard)  ;
        }), 'param');
    },

    settingsLocal: {
      get() {
        return this.settings;
      },
      set(value) {
        this.$emit('update:settings', value);
      },
    },

    automaticallyMarkOutOfRangeLocal: {
      get() {
        return this.automaticallyMarkOutOfRange;
      },
      set(value) {
        this.$emit("update:automaticallyMarkOutOfRange", value);
      },
    },
  },
  methods: {
    onValidation(event) {
      this.$emit("onValidation", event);
    },
  },
};
</script>
