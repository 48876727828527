<template>
  <div
    :class="{
      'red lighten-5': tank.discard,
      'orange lighten-5': tank.restriction,
      'deep-orange lighten-5': tank.return,
    }"
  >
    <div
      class="pa-4 tank"
      :style="(tank.discard || tank.restriction || tank.return) ? 'background: transparent !important;' : ''"
    >
      <v-row class="pa-0 ma-0">
        <v-col
          class="pa-0"
          style="min-width: 110px;"
        >
          <p
            class="text-left mb-0"
          >
            <span
              class="text-overline"
              style="font-size: 12px !important; line-height: 15px !important;"
            >
              {{ tank.label }}
            </span>
            <span
              v-if="tank.plate"
              class="text-subtitle-1"
            >
              ({{ tank.plate }})
            </span>
          </p>
          <p
            class="text-overline text-left"
            style="font-size: 12px !important; line-height: 15px !important;"
          >
            {{ tank.vol }}L / {{ tank.capacity }}L
          </p>
        </v-col>
        <v-col
          class="pa-0 d-flex justify-end"
        >
          <div
            v-if="showReturnInput"
            class="text-center mx-1"
            style="max-width: 70px;"
            @click.stop="toReturnTank"
          >
            <v-checkbox
              v-model="tank.return"
              color="deep-orange"
              class="mt-0 pa-0 discard-checkbox"
              hide-details
              @click.prevent="toReturnTank"
            />

            <span>Devolução</span>
          </div>
          <div
            class="text-center mx-1"
            style="max-width: 70px;"
            @click.stop="toRestrictTank"
          >
            <v-checkbox
              v-model="tank.restriction"
              color="orange"
              class="mt-0 pa-0 discard-checkbox"
              hide-details
              :disabled="measurementParamRequired && (tank.restriction || tank.discard)"
              @click.prevent="toRestrictTank"
            />

            <span>Restrição</span>
          </div>
          <div
            class="text-center mx-1"
            style="max-width: 70px;"
            @click.stop="toDiscardTank"
          >
            <v-checkbox
              v-model="tank.discard"
              color="red"
              class="mt-0 pa-0 discard-checkbox"
              hide-details
              :disabled="(measurementParamRequired && (tank.restriction || tank.discard)) || ['UNLOADED', 'UNLOADED_RESTRICT'].includes(tank.status)"
              @click.prevent="toDiscardTank"
            />

            <span>Descartar</span>
          </div>

          <div
            v-if="measurementParam === 'balanca'"
            class="text-center mx-1"
            style="max-width: 70px;"
            @click.stop="toMeasureTankWeight"
          >
            <v-checkbox
              v-model="tank.measureTankWeight"
              color="info"
              class="mt-0 pa-0 discard-checkbox"
              hide-details
              :disabled="measurementParamRequired && (tank.restriction || tank.discard)"
              @click.prevent="toMeasureTankWeight"
            />

            <span>Pesagem</span>
          </div>
        </v-col>
      </v-row>

      <div class="tank-analysis">
        <analysis
          ref="tankAnalysis"
          v-model="analysis"
          :vol="tank.vol"
          :problems.sync="problems"
          :empty-fields.sync="emptyFields"
          :ignore-errors="ignoreErrors"
          :ignore-empty="ignoreEmpty"
          :auto-mark-out-of-range="autoMarkOutOfRange"
          :visible="visible"
          :single-form="singleForm"
          :validate-required-fields="validateRequiredFields"
          :raw-product="rawProduct"
          :cryoscopys="cryoscopys"
          @onAutoMarkOutOfRange="onAutoMarkOutOfRange"
          @onAnalysisInputEnter="onAnalysisInputEnter"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.tank-analysis {
  margin: 0 -9px;
}

.discard-checkbox > .v-input__control > .v-input__slot {
  display: flex;
  justify-content: center;
}

</style>

<script>
import Analysis from '@/Domains/Platform/Unload/Components/Analysis.vue';
import debounce from "lodash/debounce";

export default {

  components: {
    Analysis
  },

  props: {
    tank: {
      type: Object,
      default: () => ({}),
    },

    singleForm: {
      type: Boolean,
      default: true,
    },

    visible: {
      type: Array,
      default: () => ([]),
    },

    ignoreErrors: {
      type: Array,
      default: () => ([]),
    },

    ignoreEmpty: {
      type: Array,
      default: () => ([]),
    },

    rawProduct: {
      type: Object,
      default: () => ({}),
    },

    cryoscopys: {
      type: Array,
      default: () => ([]),
    },

    autoMarkOutOfRange: Boolean,
    showReturnInput: Boolean,
  },

  computed: {

    measurementParam() {
      return this.$store.state.settings.plataforma ? this.$store.state.settings.plataforma.medicao_descarga_plataforma : 'balanca';
    },

    measurementParamRequired() {
      return this.$store.state.settings.plataforma.parametros_analise.configuracao[this.rawProduct.id] ? this.$store.state.settings.plataforma.parametros_analise.configuracao[this.rawProduct.id].marcar_automaticamente : false;
    },

    problems: {
      get() {
        return this.tank.problems;
      },
      set(newValue) {
        return this.onTankUpdated({
          ...this.tank,
          problems: newValue,
        });
      }
    },

    emptyFields: {
      get() {
        return this.tank.emptyFields;
      },
      set(newValue) {
        return this.onTankUpdated({
          ...this.tank,
          emptyFields: newValue,
        });
      }
    },

    analysis: {
      get() {
        return this.tank.analysis;
      },
      set(newValue) {
        return this.onTankUpdated({
          ...this.tank,
          analysis: newValue,
        });
      },
    },

    validateRequiredFields() {
      return true;
    },
  },

  methods: {

    focus(input) {
      return this.$refs.tankAnalysis.focus(input);
    },

    goToNextAnalysis(input) {
      return this.$refs.tankAnalysis.goToNextAnalysis(input);
    },

    onAnalysisInputEnter(input) {
      return this.$emit('onAnalysisInputEnter', { input, tank: this.tank });
    },

    onTankUpdated(updatedTank) {
      return this.$emit('onTankUpdated', updatedTank);
    },

    toMeasureTankWeight() {
      if(this.measurementParamRequired && (this.tank.restriction || this.tank.discard)) {
        return;
      }

      return this.$emit('onTankUpdated', {
        ...this.tank,
        measureTankWeight: !this.tank.measureTankWeight,
      });
    },

    toReturnTank() {
      return this.$emit('onTankUpdated', {
        ...this.tank,
        discard: false,
        restriction: false,
        return: !this.tank.return,
      });
    },

    toDiscardTank() {
      if ((this.measurementParamRequired && (this.tank.restriction || this.tank.discard)) || ['UNLOADED', 'UNLOADED_RESTRICT'].includes(this.tank.status)) {
        return;
      }

      return this.$emit('onTankUpdated', {
        ...this.tank,
        measureTankWeight: !this.tank.discard ? true : this.tank.measureTankWeight,
        discard: !this.tank.discard,
        restriction: false,
        return: false,
      });
    },

    toRestrictTank() {
      if (this.measurementParamRequired && (this.tank.restriction || this.tank.discard)) {
        return;
      }

      return this.$emit('onTankUpdated', {
        ...this.tank,
        discard: false,
        restriction: !this.tank.restriction,
        return: false,
      });
    },

    onAutoMarkOutOfRange: debounce(function (fields) {
      this.onTankUpdated({
        ...this.tank,
        ...fields,
      })
    }, 1000),

  },

}
</script>
