<template>
  <div>
    <v-dialog
      v-if="dialog.show"
      v-model="dialog.show"
      :width="`${unload.transfers.length ? '50%' : '80%'}`"
      :fullscreen="$vuetify.breakpoint.mdAndDown"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title>
          Descarga
        </v-card-title>

        <v-card-text
          class="pb-0"
        >
          <v-row
            class="text-body-2"
          >
            <v-col
              cols="4"
              sm="3"
              :lg="`${unload.transfers.length ? '3' : '2'}`"
              class="text-body-2"
              style="border-right: rgba(0, 0, 0, 0.07) 1px solid;"
            >
              <div class="pr-2">
                <span
                  class="overline"
                >
                  MATÉRIA PRIMA
                </span>
                <v-chip
                  small
                  color="#607D8B"
                  style="color: #FFFFFF;"
                >
                  {{ unload.rawProductInput.name }}
                </v-chip>
              </div>
              <div class="pr-2">
                <tanks-unload-info
                  title="TRANSFERIR"
                  :tanks="availableTanks"
                />

                <tanks-unload-info
                  title="DESCARTAR"
                  :tanks="tanksToDiscard"
                  color="red"
                  show-measured
                  :measured-disabled="hasAllTanksProcessed || transferring.length > 0 || ['vale', 'medidor'].includes(settings.measurementParam)"
                  @onTankMeasuredInput="onTankMeasuredInput"
                />

                <tanks-unload-info
                  title="DEVOLUÇÃO"
                  :tanks="tanksToReturn"
                  color="deep-orange"
                  show-measured
                  :measured-disabled="hasAllTanksProcessed || transferring.length > 0 || ['vale', 'medidor'].includes(settings.measurementParam)"
                  @onTankMeasuredInput="onTankMeasuredInput"
                />

                <masked-input
                  v-model.number="measuredVol"
                  class="pt-5 pb-4 text-uppercase"
                  suffix="Medidos (L)"
                  :mask="NumberMask"
                  :disabled="hasAllTanksProcessed || transferring.length > 0 || ['vale', 'medidor'].includes(settings.measurementParam)"
                  reverse
                  dense
                  return-unmasked
                  filled
                  hide-details
                >
                  <template #append-outer>
                    <v-btn
                      v-if="settings.measurementParam === 'medidor'"
                      icon
                      small
                      @click="reloadMeasured()"
                    >
                      <v-icon>refresh</v-icon>
                    </v-btn>
                  </template>
                </masked-input>

                <masked-input
                  v-model.number="availableVol"
                  class="pt-2 pb-4 text-uppercase"
                  suffix="Disponível (L)"
                  :mask="NumberMask"
                  disabled
                  reverse
                  dense
                  return-unmasked
                  filled
                  hide-details
                />
              </div>
            </v-col>

            <v-col
              cols="8"
              sm="9"
              :lg="`${unload.transfers.length ? '9' : '10'}`"
              class="pa-0"
            >
              <v-card
                v-if="!hasAllTanksProcessed"
                elevation="0"
              >
                <silos
                  :available-vol="availableVol"
                  :measured-vol="measuredVol"
                  :tanks="availableTanks"
                  :transferring="transferring"
                  :raw-material="unload.rawProduct ? unload.rawProduct.id : undefined"
                  :overlimit.sync="hasOverlimit"
                  @onTankTransferring="onTankTransferring"
                />
              </v-card>
              <v-card
                v-if="hasAllTanksProcessed"
                elevation="0"
                class="px-3 pb-3"
              >
                <v-card-title
                  class="pt-0"
                >
                  <span class="text-h6">
                    Transferências Realizadas
                  </span>
                </v-card-title>

                <v-data-table
                  v-if="hasAllTanksProcessed"
                  :headers="[
                    { text: 'Tanque', value: 'tank', },
                    { text: 'Silo', value: 'siloLabel', },
                    { text: 'Volume', value: 'vol', },
                  ]"
                  title="Transferências Realizadas"
                  :items="unload.transfers"
                  disable-pagination
                  disable-sort
                  disable-filtering
                  hide-default-footer
                >
                  <template #[`item.tank`]="{ item }">
                    {{ item.tank === 'diferenca' ? 'Diferença' : tanksLabels[item.tankIndex] }}
                  </template>

                  <template #[`item.vol`]="{ value }">
                    {{ value }} L
                  </template>
                </v-data-table>
                <v-row
                  class="pa-1 ma-0 mb-2 flex-column"
                >
                  <v-col class="pa-0 ma-0 text-right blue--text">
                    Total volume: {{ totalVolume() }} L
                  </v-col>
                  <v-col
                    v-if="totalDescarte() > 0"
                    class="pa-0 ma-0 text-right red--text"
                  >
                    Total descarte: {{ totalDescarte() }} L
                  </v-col>
                  <v-col
                    v-if="totalDevolucao() > 0"
                    class="pa-0 ma-0 text-right deep-orange--text"
                  >
                    Total devolução: {{ totalDevolucao() }} L
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="grey darken-3"
            text
            @click="hide"
          >
            CANCELAR
          </v-btn>

          <v-spacer />

          <v-btn
            v-if="isUnloadAvailable"
            color="blue darken-1"
            text
            outlined
            @click="onUnloadAllTanks"
          >
            TRANSFERIR
          </v-btn>
          <v-btn
            v-if="hasAllTanksProcessed && unload.transfers.length > 0"
            color="red darken-1"
            text
            outlined
            :disabled="!canReverseTransfers"
            @click="onTransfersRevertClick"
          >
            REVERTER TRANSFERÊNCIAS
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="confirmationDialog.show"
      max-width="400"
      persistent
    >
      <v-card>
        <v-card-title class="text-h5">
          Atenção
        </v-card-title>
        <v-card-text>
          <v-form
            ref="confirmationDialogForm"
            lazy-validation
            @submit.prevent=""
          >
            <div v-html="confirmationDialog.content" />

            <v-text-field
              v-model.number="confirmationDialog.typedToken"
              :rules="[v => (v && v === confirmationDialog.confirmationToken) || 'Token de confirmação incorreto!']"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey darken-1"
            text
            @click="hideConfirmationDialog()"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="onTransfersRevert()"
          >
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="scss">
.measured-input {
  font-size: 10px;

  .v-input__control {
    padding: 0 !important;
    min-height: 0 !important;

    .v-input__slot {
      padding: 0 !important;
    }

    .v-text-field__slot > input {
      padding: 0 !important;
      color: rgba(0, 0, 0, 0.6);
      letter-spacing: 0.1666666667em !important;
    }
  }

  .v-input__append-outer {
    line-height: 10px !important;
    margin-top: 6px !important;
    margin-left: 4px !important;
    margin-right: 1px !important;
    color: rgba(0, 0, 0, 0.6);
  }
}
</style>

<script>
import * as _ from "lodash";
import moment from "moment";

import MaskedInput from "@/Support/Components/MaskedInput.vue";
import Silos from "@/Domains/Platform/Unload/Components/Silos.vue";
import TanksUnloadInfo from "@/Domains/Platform/Unload/Components/TanksUnloadInfo.vue";

export default {

  components: {
    MaskedInput,
    Silos,
    TanksUnloadInfo,
  },

  props: {

    unload: {
      type: Object,
      default: () => ({}),
    },

    measured: {
      type: Number,
      default: 0,
    },

    tanks: {
      type: Array,
      default: () => ([]),
    },

  },

  data() {
    return {

      dialog: {
        show: false,
      },

      transferring: [],

      hasOverlimit: false,

      confirmationDialog: {
        show: false,
        content: '',
        confirmationToken: '',
      },

      // Mascara usada nos damais campos numéricos
      NumberMask: {
        mask: 'num',
        blocks: {
          num: {
            mask: Number,
            signed: true,
            scale: 2,
            max: 160000,
          },
        },
      },
    };
  },

  computed: {

    isFlowMeter() {
      if (['vazao', 'medidor'].includes(this.settings.measurementParam)) {
        return true;
      }
      return false;
    },

    isUnloadAvailable() {

      if (this.measured === 0 && ['vazao', 'medidor'].includes(this.settings.measurementParam)) {
        return false;
      }

      if (this.hasOverlimit && this.validateSiloVol) {
        return false;
      }

      return this.availableVol === 0 && !this.hasAllTanksProcessed;
    },

    settings() {

      const settings = this.$store.state.settings.plataforma;

      return {
        measurementParam: settings.medicao_descarga_plataforma || 'balanca',
      };
    },

    availableTanks() {
      let tanks = this.tanks.filter(tank => {
        return ['TO_UNLOAD', 'UNLOADED', 'TO_UNLOAD_RESTRICT', 'UNLOADED_RESTRICT'].includes(tank.status);
      });

      let allTanks = _.flatten(this.transferring.map(transferring => transferring.tanks));

      const transferringVolByTank = _.groupBy(allTanks, 'index');

      tanks = tanks.map(tank => {
        const transferringVol = _.sumBy(_.get(transferringVolByTank, tank.index), 'transferring');

        if (['UNLOADED', 'UNLOADED_RESTRICT'].includes(tank.status)) {
          return {
            ...tank,
            availableVolToTransfer: 0,
            transferring: 0,
          };
        }

        return {
          ...tank,
          availableVolToTransfer: tank.availableVol - transferringVol,
          transferring: transferringVol,
        };
      });

      return tanks;
    },

    tanksLabels() {
      return this.tanks.reduce((acc, tank) => {
        acc[tank.index] = tank.plate ? `${tank.label} (${tank.plate})` : tank.label;
        return acc;
      }, {})
    },

    hasAllTanksProcessed() {
      const processedStatus = [ 'UNLOADED', 'TO_DISCARD', 'DISCARDED', 'UNLOADED_RESTRICT', 'TO_RETURN', 'RETURNED' ];

      if (!_.isEmpty(this.unload.transfers)) {
        return true;
      }

      return this.unload.tanks.every(tank => processedStatus.includes(tank.status));
    },

    measuredVol: {
      get() {
        return this.measured;
      },
      set(newValue) {
        return this.$emit('onMeasuredChange', newValue || 0);
      }
    },

    transferringVol() {

      if (_.isEmpty(this.transferring)) {
        return 0;
      }

      let allTanks = _.flatten(this.transferring.map(transferring => transferring.tanks));

      return _.sumBy(allTanks, 'transferring');
    },

    availableVol() {
      if (_.isEmpty(this.unload)) {
        return 0;
      }

      if (!_.isEmpty(this.unload.transfers)) {
        return 0;
      }

      // if (['vazao', 'medidor'].includes(this.settings.measurementParam) && this.measuredVol === 0) {
      //   return 0;
      // }

      const allTanksVol = _.sumBy(this.tanks, tank => {
        if (['TO_UNLOAD', 'TO_UNLOAD_RESTRICT'].includes(tank.status)) {

          if (this.measuredVol === 0) {
            return tank.vol;
          }

          return tank.vol + tank.difference;
        }

        return 0;
      });

      if (this.measuredVol > 0) {
        const availableVol = allTanksVol - this.transferringVol;

        if (availableVol === this.difference) {
          return 0
        }

        return availableVol;
      }

      return allTanksVol - this.transferringVol;
    },

    difference() {

      const measured = parseInt(this.measured) || 0;

      if (this.settings.measurementParam === 'balanca' && measured === 0) {
        return 0;
      }

      const totalVol = _.sumBy(this.tanks, 'vol');

      if (totalVol === measured) {
        return 0;
      }

      return measured - totalVol;
    },

    tanksToUnload() {
      return this.tanks.filter(tank => ['TO_UNLOAD', 'UNLOADED', 'TO_UNLOAD_RESTRICT', 'UNLOADED_RESTRICT'].includes(tank.status));
    },

    tanksToDiscard() {
      return this.tanks.filter(tank => tank.status === 'TO_DISCARD' || tank.status === 'DISCARDED');
    },

    tanksToReturn() {
      return this.tanks.filter(tank => tank.status === 'TO_RETURN' || tank.status === 'RETURNED');
    },

    validateSiloVol() {
      return this.$store.state.settings.gerais.validar_saldo_silo;
    },

    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    canReverseTransfers() {
      return this.$store.state.settings.user.id_cargo === 1 || this.userResources.some(o => o.recurso === 'reverse-transfers' && o.tipo === 'COMPONENTE');
    }
  },

  methods: {

    show() {
      this.dialog.show = true;
    },

    hide() {
      this.dialog.show = false;
      this.transferring = [];

      return this.hideConfirmationDialog();
    },

    onTankTransferring({ silo, transferring }) {

      if (_.isEmpty(transferring) && !_.isEmpty(this.transferring)) {
        this.transferring = this.transferring.filter(transferringSilo => transferringSilo.id !== silo.id);

        return;
      }

      const hasSiloInTransfers = this.transferring.find(transferringSilo => transferringSilo.id === silo.id);

      if (!hasSiloInTransfers) {
        this.transferring = [
          ...this.transferring,
          {
            id: silo.id,
            tanks: transferring.map(({ index, transferring }) => ({ index, transferring })),
          },
        ];

        return;
      }

      this.transferring = this.transferring.map(transferringSilo => {

        if (silo.id === transferringSilo.id) {
          return {
            id: silo.id,
            tanks: transferring.map(({ index, transferring }) => ({ index, transferring })),
          };
        }

        return transferringSilo;
      });
    },

    async onUnloadAllTanks() {
      try {
        this.$root.$progressBar.saving();

        if (_.isEmpty(this.transferring)) {
          throw 'Nenhum silo para transferência foi identificado';
        }

        if (['vazao', 'medidor'].includes(this.settings.measurementParam) &&  this.measured === 0 ) {
          this.$snotify.error("Informe o volume de medidos", "Atenção");
          return false;
        }

        const vale = _.isObject(this.unload.vale) ? vale.current : this.unload.vale;
        const measured = this.measuredVol;

        const settings = this.$store.state.settings?.plataforma || {};

        let limit;
        let block;

        if (this.unload.itinerary.type === 'coleta') {
          limit = settings?.limite_diferenca_medidos_percentual;
          block = settings?.acao_diferenca_medidos === 'BLOQUEAR';
        } else if (this.unload.itinerary.type === 'spot' || this.unload.itinerary.type === 'transferencia') {
          limit = settings?.limite_diferenca_medidos_percentual_spot;
          block = settings?.acao_diferenca_medidos_spot === 'BLOQUEAR';
        }

        if (measured && limit && block) {
          const diff = (1 - (measured > vale ? (vale / measured) : (measured / vale))) * 100;

          if (diff > limit) {
            this.$snotify.warning(
              `Limite de diferença de medidos excedido. Diferença: ${diff}%. Limite ${limit}%`,
              "Bloqueado"
            );
            return false;
          }
        }

        const unloadStatus = {
          'WAITING': 'DESCARREGANDO',
          'PROCESSING': 'PROCESSANDO',
          'UNLOADED': 'DESCARREGADOS',
        }

        let tanks = this.availableTanks.map(tank => {

          const silos = this.transferring.map(silo => {

            const tankUnload = silo.tanks.find(unloadedTank => unloadedTank.index === tank.index);

            if (!tankUnload) {
              return null;
            }

            return {
              id: silo.id,
              vol: tankUnload.transferring,
            }
          }).filter(n => n);

          return {
            data: moment().format('YYYY-MM-DD'),
            tanque: `tanque${tank.index}`,
            status: tank.restriction ? 'DESCARREGADO_RESTRITO' : 'DESCARREGADO',
            silos: silos.map(({ id, vol }) => {
              return {
                id_silo: id,
                volume: vol,
              };
            }),
          }
        });

        const { data } = await this.$axios.post(
          `/silo/transferenciaTanqueSilo`,
          {
            id_descarga_coletas: this.unload.id,
            peso_medido: this.measured,
            id_materia_prima: this.unload.rawProduct.id,
            nome_materia_prima: this.unload.rawProduct.name,
            status: unloadStatus[this.unload.status],
            transferencias: tanks,
            descartes: this.tanksToDiscard.map(tank => ({
              tanque: `tanque${tank.index}`,
              volume: tank.availableVol,
            })),
            tanques: this.tanks.map(tank => {
              return {
                tanque: `tanque${tank.index}`,
                medidos: tank.measured,
              }
            }),
            medidor_vazao: this.isFlowMeter
          },
        );

        const { codigo } = data;

        if (!codigo) {
          throw 'Erro ao salvar';
        }
        if (data.codigo === 2) {
          this.$snotify.warning(
            data.mensagem,
            "Atenção"
          );
          return false;
        }

        this.hide();

        return this.$emit('onTanksUnloaded', true);
      } catch (e) {
        console.log(e);

      } finally {
        this.$root.$progressBar.hide();
      }
    },

    hideConfirmationDialog() {
      this.confirmationDialog = {
        show: false,
        content: '',
        confirmationToken: '',
      };
    },

    onTransfersRevertClick() {
      this.confirmationDialog.show = true;
      this.confirmationDialog.content = `
      Deseja realmente reverter TODAS as transferências desta descarga?
        <br /> <br />
      Esta ação não poderá ser revertida!
        <br /> <br />
      Para continuar, por favor digite <b>REVERTER</b> no campo abaixo:
      `;
      this.confirmationDialog.confirmationToken = 'REVERTER';
    },

    async onTransfersRevert() {
      try {
        this.$root.$progressBar.saving();

        const valid = this.$refs.confirmationDialogForm.validate();

        if (!valid) {
          return;
        }

        const { data } = await this.$axios.post(
          `/descargaColeta/reverteTransferenciasDescarga`,
          {
            id_descarga_coletas: this.unload.id,
          },
        );

        this.hide();

        if (!data.codigo) {
          throw new Error(data.mensagem || data);
        }
        if (data.codigo === 2) {
          this.$snotify.warning(
            data.mensagem,
            "Atenção"
          );
          return false;
        }

        return this.$emit('onTanksUnloaded', false);
      } catch (e) {
        console.log(e);
        const message = e.message || e;
        this.$snotify.error(message, "Atenção");
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    onTankMeasuredInput(measured, updatedTank) {
      const tanks = this.tanks.map(tank => {
        if (tank.index !== updatedTank.index) {
          return tank;
        }

        return {
          ...tank,
          measured: parseInt(measured) || 0,
        }
      });

      return this.$emit('update:tanks', tanks);
    },

    async reloadMeasured() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.post(`/descargaColeta/pesoMedido`, {
          id_descarga_coletas: this.unload.id,
        });

        this.measuredVol = parseFloat(data.peso_medido) || 0;

      } catch (e) {
        console.log(e);
        const message = e.message || e;
        this.$snotify.error(message, "Atenção");
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    totalVolume() {
      return this.unload.transfers.length > 0 ? this.unload.transfers.reduce((total, tank) => total + tank.vol, 0) : 0;
    },

    totalDescarte() {
      return this.tanksToDiscard.length > 0 ? this.tanksToDiscard.reduce((total, tank) => total + tank.availableVol, 0) : 0;
    },

    totalDevolucao() {
      return this.tanksToReturn.length > 0 ? this.tanksToReturn.reduce((total, tank) => total + tank.availableVol, 0) : 0;
    }
  },
}
</script>
