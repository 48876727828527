<template>
  <div class="pt-0">
    <v-row>
      <v-col cols="12">
        <v-card dark color="transparent">
          <v-card-title>
            <v-spacer />
            <v-col cols="4" md-4 sm-4 class="pa-0">
              <v-text-field
                v-model="filter.search"
                append-icon="search"
                label="Buscar"
                single-line
                hide-details
                dark
                class="pt-0 my-2"
              />
            </v-col>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="items"
            :search="filter.search"
            dark
            @click:row="edit"
          >
            <template #[`item.state`]="{ item }">
              {{ statesList[item.state] }}
            </template>
            <template #[`item.action`]="{ item }">
              <v-menu bottom right>
                <template #activator="{ on }">
                  <v-btn icon v-on="on">
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="remove(item)">
                    <v-list-item-icon>
                      <v-icon>delete</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Excluir</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="edit(item)">
                    <v-list-item-icon>
                      <v-icon>edit</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Editar</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn color="blue darken-2" dark large fab>
          <v-icon>menu</v-icon>
        </v-btn>
      </template>
      <v-btn fab dark color="orange darken-1" @click="add">
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              add
            </v-icon>
          </template>
          Novo
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
    <add-exam-expiration-settings-dialog v-model="showDialog" :edit-id.sync="editId" @save="loadExpirationSettings" />
  </div>
</template>

<script>
import AddExamExpirationSettingsDialog from "@/Domains/Projects/HealthExamination/Components/AddExamExpirationSettingsDialog.vue";

export default {
  components: {
    AddExamExpirationSettingsDialog,
  },

  props: {
    statesList: {
      type: Object,
      deep: true
    }
  },

  data() {
    return {
      showDialog: false,
      editId: null,

      filter: {
        search: "",
      },

      headers: [
        { text: "Estado", value: "state" },
        { text: "Vencimento brucelose", value: "brucelose" },
        { text: "Vencimento tuberculose", value: "tuberculose" },
        { text: "Opções", value: "action", width: 30, align: 'center' },
      ],

      period: [
        { value: 1, text: "Mensal" },
        { value: 2, text: "Bimestral" },
        { value: 3, text: "Trimestral" },
        { value: 6, text: "Semestral" },
        { value: 12, text: "Anual" },
        { value: 24, text: "Bianual" },
        { value: 36, text: "Trianual" },
      ],

      items: [],
    };
  },

  mounted() {
    this.loadExpirationSettings();
  },

  methods: {
    async loadExpirationSettings() {
      try {
        this.$root.$progressBar.loading();
        const { data } = await this.$axios.get(`/projects/health-exam-expiration-settings`);

        this.items = data.map(item => {
          return {
            id: item.id,
            state: item.estado,
            brucelose: this.period.find(e => e.value == item.prazo_brucelose).text,
            tuberculose: this.period.find(e => e.value == item.prazo_tuberculose).text,
          };
        });
      } catch (err) {
        console.warn(err);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    add() {
      this.editId = null;
      this.showDialog = true;
    },

    edit(item) {
      this.editId = item.id;
      this.showDialog = true;
    },

    async remove({ id }) {
      if (!(await this.$root.$confirm('Remover registro', 'Tem certeza que deseja remover este registro?', { color: 'red' }))) {
        return;
      }
      try {
        this.$root.$progressBar.loading();

        await this.$axios.delete(`/projects/health-exam-expiration-settings/${id}`);
        this.$snotify.success("Registro excluído com sucesso", "Sucesso");
      } catch (error) {
        this.$snotify.error("Erro ao excluir registro", "Atenção");
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
        await this.loadExpirationSettings();
      }
    },
  },
};
</script>
