<template>
  <div>
    <v-card
      flat
      class="pa-0 mr-2"
    >
      <span class="ml-1 overline">Análises</span>
      <v-tooltip top>
        <template #activator="{ on }">
          <v-icon
            small
            v-on="on"
          >
            info
          </v-icon>
        </template>
        Os parâmetros marcados abaixo serão exibidos para análise
      </v-tooltip>
      <v-row class="tree-content">
        <v-treeview
          v-model="analysis"
          color="#5438A7"
          open-all
          selectable
          item-disabled="locked"
          :items="analysisItems"
          selected-color="cyan darken-3"
          return-object
          active-class
          dense
          :style="{
            'max-height': $vuetify.breakpoint.xlOnly ? '80vh !important' : '70vh !important'
          }"
        />
      </v-row>
    </v-card>

    <v-overlay
      :value="loading"
      absolute
    >
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </div>
</template>

<style lang="scss">
.tree-card {
  border: 1px solid #ccc !important;
  border-radius: 10px !important;

  .v-treeview {
    > .v-treeview-node__root {
      padding: 0;
    }
  }
}

.tree-content {
  max-height: calc(100vh -200px) !important;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: hidden;
}

.tree-analysis{
  max-height: 480px !important;
}
</style>

<script>
import _ from 'lodash';

import analysisParams from './Services/AnalysisParams.js';

export default {
  props: {
    value: {
      type: Array,
    },
    rawMaterialId: {
      type: String,
    },
    settings: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      analysisItems: [],
      analysis: [],

      loading: false,

    };
  },
  computed: {
    settingsLocal: {
      get() {
        return this.settings;
      },
      set(value) {
        this.$emit('update:settings', value);
      },
    },
  },
  watch: {
    analysis(newValue) {
      this.$emit('input', newValue);
    }
  },

  mounted() {
    this.buildAnalysisItems();
    this.loadAnalysis();
  },

  methods: {
    /**
     * @void
     * Método para tratar as informações das arvores
     */
    buildAnalysisItems() {
      this.analysisItems = analysisParams.map((analysis) => ({
        id: analysis.param,
        name: analysis.text,
        param: analysis.param,
        standardDescription: analysis.standardDescription,
        children: (analysis.depends || []).map(depend => ({
          id: depend.param,
          name: depend.text,
          param: depend.param,
          depend: analysis.param,
          dependencyName: analysis.text,
          analysis: depend,
          standardDescription: depend.standardDescription,
        })),
        dependencies: (analysis.depends || []).map(depend => depend.param),
        analysis
      }));
    },

    /**
     * Recupera as analises já configuras
     * @void
     */
    async loadAnalysis() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(`/configuracao/listaConfiguracoesPlataforma`);

        const selectedAnalysis = data.parametros_analise.materia_prima[this.rawMaterialId] || [];

        this.analysis = selectedAnalysis.map(data => {
          const analysisParam = this.analysisItems.find(item => item.param.trim() === data.parametro.trim());

          // Vai setar as análises que são dependentes (filhas)
          // Caso não encontrar a analise na raiz, indica que é uma analise dependente
          if (!analysisParam) {
            const parentAnalysis = this.analysisItems.find(item => item.dependencies.includes(data.parametro));

            if (!parentAnalysis) {
              return null;
            }

            return parentAnalysis.children.find(item => (item.param.trim() === data.parametro.trim()));
          }

          const { analysis } = analysisParam;

          // Não precisa setar o pai. O importante é tratar os filhos
          if (_.has(analysis, 'depends')) {
            return null;
          }

          const parseAnalysis = {
            param: data.parametro,
            text: analysis.text,
            standard: data.padrao,
            standardDescription: data.descricao_padrao,
            restriction: data.restricao || {},
          };

          if (_.has(data, 'unidade_medida') && !_.isEmpty(analysis.units)) {
            parseAnalysis.units = analysis.units.map(unit => ({
              ...unit,
              selected: unit.description === data.unidade_medida.descricao
            }));
          }

          analysisParam.analysis = parseAnalysis;

          return analysisParam;
        }).filter(a => a);

        this.settingsLocal = {
          showRestriction: _.get(data.parametros_analise, `configuracao.${this.rawMaterialId}.possui_config_restricao`, false),
          automaticallyMarkOutOfRange: _.get(data.parametros_analise, `configuracao.${this.rawMaterialId}.marcar_automaticamente`, false),
        };

      } catch (e) {
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },
  }
};
</script>
